import '../css/subscription.css'

import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom"
import { apiUrl } from "../../function";
import Cookies from "js-cookie";

export default function Subscription() {

    const [packageData, setpackageData] = useState({
        package_name: '',
        price: '',
        num_of_template: '',
        cover_letter: '',
        credit_points: '',
        premium_powerful_AI: '',
        water_mark: '',
        premium_template: '',
        email_support: '',
        phone_support: '',
        portfolio: '',
        custom_domain: '',

    });

    // Get the userId param from the URL.
    //  let { packageId } = useParams();
    var urlParams = new URLSearchParams(window.location.search);
    var packageId = urlParams.get('packageId');

    useEffect(() => {
        reflectPackageData(packageId);
    }, [])

    const reflectPackageData = async (packageId) => {

        try {
            const response = await axios.get(apiUrl() + `/get-package-master/${packageId}`);
            if (response.data.statusCode == "200") {

                const pckData = {
                    package_name: response.data.packageData[0].package_name,
                    price: response.data.packageData[0].price,
                    num_of_template: response.data.packageData[0].num_of_template,
                    cover_letter: response.data.packageData[0].cover_letter,
                    credit_points: response.data.packageData[0].credit_points,

                    premium_powerful_AI: response.data.packageData[0].premium_powerful_AI,
                    water_mark: response.data.packageData[0].water_mark,
                    premium_template: response.data.packageData[0].premium_template,
                    email_support: response.data.packageData[0].email_support,
                    phone_support: response.data.packageData[0].phone_support,
                    portfolio: response.data.packageData[0].portfolio,
                    custom_domain: response.data.packageData[0].custom_domain,
                }

                setpackageData(pckData);
            }
            else {

            }
        } catch (error) {

        }
    }

    const payAmount = async (price) => {

        let paymentSuccess = true;
        if (paymentSuccess) {
            let token = Cookies.get('userAuth')
            try {
                const response = await axios.post(apiUrl() + '/post-user-subscription-data', { packageId },
                    { headers: { 'Authorization': `Bearer ${token}` } });
                if (response.data.statusCode == "200") {

                } else {

                }
            } catch (error) {

            }

        }
    }

    return (
        <>
            <div>
                <div className="subscription" style={{ marginTop: '112px' }}>
                    <div className="subscription-container">
                        {/* <header className="section-header">
                            <h2>Pricing</h2>

                        </header> */}


                        <div className="container"    >
                            {/* <img src="assets/img/pricing-ultimate.png" className="img-fluid" alt="" />
                            Total Bill ----   {packageData.price} <br />
                            Plan Name--- {packageData.package_name}  <br /> */}



                            <h3 style={{ color: "#07d5c0" }}>{packageData.package_name}</h3>
                            <div className="price"><sup>₹</sup>{packageData.price}<span> / mo</span></div>
                            <img src="assets/img/pricing-ultimate.png" className="img-fluid" alt="" />


                            <div className=" mt-2 mb-3">

                                <div id="pricing-content-4">
                                    <div >
                                        <table style={{ width: "100%" }}>
                                            <tbody style={{ fontSize: "14px" }}>
                                                <tr>
                                                    <td>Number of Templates</td>
                                                    <td>{packageData.num_of_template}</td>
                                                </tr>
                                                <tr>
                                                    <td>Cover Letter</td>
                                                    <td>{packageData.cover_letter}</td>
                                                </tr>
                                                <tr>
                                                    <td>Normal AI Edits Per Year</td>
                                                    <td>{packageData.credit_points}</td>
                                                </tr>
                                                <tr>
                                                    <td>Premium 10X Powerful AI Engine</td>
                                                    <td>{packageData.premium_powerful_AI}</td>
                                                </tr>
                                                <tr>
                                                    <td>MagicPoint Water Mark</td>
                                                    <td>{packageData.water_mark}</td>
                                                </tr>
                                                {/* <tr>
                                                    <td>Download Option</td>
                                                    <td>{packageData.download_option}</td>
                                                </tr> */}
                                                <tr>
                                                    <td>Premium Templates</td>
                                                    <td>{packageData.premium_template}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email Support</td>
                                                    <td>{packageData.email_support}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone Support</td>
                                                    <td>{packageData.phone_support}</td>
                                                </tr>
                                                <tr>
                                                    <td>Portfolio</td>
                                                    <td>{packageData.portfolio}</td>
                                                </tr>
                                                <tr>
                                                    <td>Custom Domain Portfolio</td>
                                                    <td>{packageData.custom_domain}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <button type="button" className="btn btn-primary" onClick={() => { payAmount(packageData.price) }}>Pay {packageData.price} </button>

                        </div>
                    </div>

                </div>

            </div>



            {/*
            <section id="pricing" className="pricing" >

                <div className="container" >

                    <header className="section-header">
                        <h2>Pricing</h2>

                    </header>

                    <div className="row gy-4" >

                        <>
                            <div className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400" >
                                <div className="box">
                                    <h3 style={{ color: "#07d5c0" }}>{packageData.price}</h3>
                                    <div className="price"><sup>₹</sup>{packageData.price}<span> / mo</span></div>
                                    <img src="assets/img/pricing-ultimate.png" className="img-fluid" alt="" />

                                    <div className=" mt-2 mb-3">

                                        <div id="pricing-content-4">
                                            <div >
                                                <table style={{ width: "100%" }}>
                                                    <tbody style={{ fontSize: "14px" }}>
                                                        <tr>
                                                            <td>Number of Templates</td>
                                                            <td>{packageData.num_of_template}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Cover Letter</td>
                                                            <td>{packageData.cover_letter}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Normal AI Edits Per Year</td>
                                                            <td>{packageData.normal_ai}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Premium 10X Powerful AI Engine</td>
                                                            <td>{packageData.premium_powerful_AI}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>MagicPoint Water Mark</td>
                                                            <td>{packageData.water_mark}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Download Option</td>
                                                            <td>{packageData.download_option}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Premium Templates</td>
                                                            <td>{packageData.premium_template}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Email Support</td>
                                                            <td>{packageData.email_support}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Phone Support</td>
                                                            <td>{packageData.phone_support}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Portfolio</td>
                                                            <td>{packageData.portfolio}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Custom Domain Portfolio</td>
                                                            <td>{packageData.custom_domain}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <Link to={`/subscription?packageId=${packageData.package_list_id}`} className="btn-buy ">Buy Now</Link>
                                </div>
                            </div>
                        </>

                    </div>
                </div>
            </section> */}

        </>
    );

}
import { Modal } from 'react-responsive-modal';

export default function LoaderComp() {
    return (

        <div className="d-flex justify-content-center align-items-center p-200" >
            <div className="spinner-border " role="status">
                <span className="sr-only ">Loading...</span>
            </div>
        </div >

    );
}

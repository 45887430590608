import React, { useRef } from "react";
import { useEffect, useState } from "react"
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { apiUrl } from "../function"
import axios from "axios";
import Cookies from "js-cookie";
import TextField from '@mui/material/TextField';
import { aiAPIService, getCreditPoint, deductCreditPoints, getCreditPointDeducts, jwtVerify, validateEmail, validatePhoneNumber } from "../function";
import { Link } from "react-router-dom"
import { Aisuggesstionmodal } from "./modal/Aimodal";
import 'react-responsive-modal/styles.css';
import './css/model.css'

const TextEditor = () => {

  const [content, setContent] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isModelButtonVisible, setIsModelButtonVisible] = useState(false);
  const [creditPoints, setCreditPoints] = useState(null);
  const [creditPointsDeduct, setCreditPointsDeduct] = useState(null);
  const [aiSuggesstion, setAiSuggesstion] = useState();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const quillRef = useRef(null);

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
        { align: [] }
      ],
      [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
    ]
  };

  var formats = [
    "header", "height", "bold", "italic",
    "underline", "strike", "blockquote",
    "list", "color", "bullet", "indent",
    "link", "image", "align", "size",
  ];

  const handleProcedureContentChange = (value) => {
    setContent(value);
    if (content.length > 20) {
      setIsModelButtonVisible(true);
    }
  };
  const handleProcedureAiContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleChanges = (event) => {
    setCompanyName(event.target.value);
  };


  const handleSubmit = async () => {
    let token = Cookies.get('userAuth');

    const formData = {
      companyName: companyName,
      content: content,
    };
    try {
      const response = await axios.post(apiUrl() + '/post-user-cover-page',
        formData,
        { headers: { 'Authorization': `Bearer ${token}` } })
      if (response.data.statusCode == "200") {
        console.log(response.data.status);
        alert('Cover Letter Saved');
        window.location.reload();
      } else {
        console.log(response.data.status)
      }
    } catch (error) {
      console.error(error);
    }
  }
  const onOpenModal = (content, content_type) => {
    callAIApi(content, content_type);
    setOpen(true);
  }
  const onCloseModal = () => setOpen(false);
  const callAIApi = async (content, content_type) => {
    setIsLoading(true);
    let aiAPIRes = await aiAPIService(content, content_type);
    if (aiAPIRes) {
      setIsLoading(false)

      setAiSuggesstion(aiAPIRes);
    }
    else {
      setIsLoading(true);
    }
  }
  const getCreditP = async () => {
    let token = Cookies.get('userAuth')
    let creditPT = await getCreditPoint(token);
    if (creditPT) {
      setCreditPoints(creditPT);
    }
  }
  const getCreditPointDeduct = async () => {
    let creditPointDeduct = await getCreditPointDeducts();
    if (creditPointDeduct) {
      setCreditPointsDeduct(creditPointDeduct);
    }
  }
  const deductCreditPts = async (value) => {
    let token = Cookies.get('userAuth')
    if (creditPoints >= value) {
      let availCreditPoints = creditPoints - value;
      let updateCP = await deductCreditPoints(availCreditPoints, token);
      setCreditPoints(availCreditPoints);
      getCreditP();
    }
    else if (creditPoints == 0) {
      setCreditPoints(0);
    }
  };

  useEffect(() => {

    // API call for getCreditPoint ---------getCreditPointDeduct-------------
    getCreditP();
    // getCreditPointDeduct();
  }, []);



  return (
    <>

      <div className="row form-group" style={{ height: '5vh', width: '80vw' }}>
        <div className="col-sm-3 companyLabel">
          Your Company Name:
        </div>
        <div className="col-sm-3">
          <input
            name="companyName"
            onChange={handleChanges}
            type="text" class="form-control" id="companyName"
            placeholder="Enter Company Name" />
        </div>
        <div className="col-sm-3">
          {isModelButtonVisible ? (
            creditPoints > 0 ? (
              <a>
                <button
                  type="button"
                  className="btn btn-link creds"
                  onClick={() => {
                    onOpenModal(content, 'cover latter');
                  }}
                >
                  View Suggestion
                </button>
              </a>
            ) : (
              // <label className="text-base font-medium text-gray-700 col-span-full mt-3">Get More Credit Point</label>
              <Link to='/pricing' className="btn btn-link nocreds">
                Get More Credit Point</Link>
            )
          ) : (
            <p></p>
          )}

          {isModelButtonVisible ?
            <Aisuggesstionmodal
              isLoading={isLoading}
              open={open}
              aiSuggesstion={aiSuggesstion}
              handlechangeAitext={handleProcedureAiContentChange}
              closeModal={onCloseModal}
              ondeductCreditPts={deductCreditPts}
              for={"descp"}
            />
            : ''}
        </div>
        <div className="col-sm-3">
          <button onClick={handleSubmit} style={{ backgroundColor: "#4154f1", borderRadius: '3px', padding: "8px 20px", color: "#fff", width: '120px' }} >Save </button>

        </div>
      </div>

      <div className="row">
        <div className="col-sm-12">
          <div style={{ display: "flex", flexDirection: "column", width: '100vw', marginTop: '15px' }} className="">

            <ReactQuill
              name="user_cover_letter"
              theme="snow"
              value={content}
              onChange={handleProcedureContentChange}
              style={{ border: 'none!important', height: '90vh', width: '80vw', marginBottom: '60px' }}
            />

            {/* <button onClick={handleSubmit} style={{ zIndex: 1, margin: '20px auto', backgroundColor: "#4154f1", borderRadius: '3px', padding: "8px 20px", color: "#fff", width: '120px' }} >Save </button> */}


          </div>
        </div>
      </div>

    </>
  );

}

export default TextEditor;
import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Cookies from "js-cookie";
import { useState } from 'react';
import Header from './components/header';
import Footer from './components/footer';
import LoginPage from './pages/login';
import Home from './pages/home';
import About from './pages/about';
import Buildresume from './pages/buildresume';
import Rdesign from './pages/rdesign';
import GetStarted from './pages/GetStarted';
import Contact from './components/contactus';
import Register from './pages/Register';
import Pricing from './pages/pricing';
import { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import TermsandConditons from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Dashboard from './pages/dashboard';
import MyDetails from './pages/MyDetails';
import Profileupdate from './pages/profileupdate'
import Forgetpassword from './pages/forget-password';
import Changepassword from './pages/verify';
import Creditpoints from './pages/credit-points';
import Currentplan from './pages/current-plan';
import CoverLetter from './pages/CoverLetter';
import Subscription from './components/form/Subscription';
import UserCoverLetter from './pages/UserCoverLetter';
import Checkout from './pages/checkout';
import UpdateUserCoverLetter from './pages/UpdateUserCoverLetter';

function App() {
  const [cookies, setCookie] = useState(undefined)


  useEffect(() => {
    AOS.init();
    let cookie = Cookies.get('userAuth')
    setCookie(cookie)
  }, [])



  return (
    <>
      <Header />
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forget-password" element={<Forgetpassword />} />
        <Route path="/about" element={<About />} />
        {/* <Route path="/buildresume" element={<Buildresume />} /> */}
        <Route path="/buildresume" element={Cookies.get('userAuth') != undefined ? <Buildresume /> : <LoginPage />} />

        <Route path="/GetStarted" element={<GetStarted />} />
        <Route path="/contactus" element={<Contact />} />
        <Route path="/register" element={<Register />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/termsandconditions" element={<TermsandConditons />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/verify" element={<Changepassword />} />
        <Route path="/rdesign" element={Cookies.get('userAuth') != undefined ? <Rdesign /> : <LoginPage />} />
        <Route path="/dashboard" element={Cookies.get('userAuth') != undefined ? <Dashboard /> : <LoginPage />} />
        <Route path="/MyDetails" element={Cookies.get('userAuth') != undefined ? <MyDetails /> : <LoginPage />} />
        <Route path="/userCoverLetter" element={Cookies.get('userAuth') != undefined ? <UserCoverLetter /> : <LoginPage />} />
        <Route path="/profileupdate" element={Cookies.get('userAuth') != undefined ? <Profileupdate /> : <LoginPage />} />
        <Route path="/credit-points" element={Cookies.get('userAuth') != undefined ? <Creditpoints /> : <LoginPage />} />
        <Route path="/current-plan" element={Cookies.get('userAuth') != undefined ? <Currentplan /> : <LoginPage />} />
        <Route path="/coverLetter" element={cookies != undefined ? <CoverLetter /> : <LoginPage />} />
        <Route path="/subscription" element={cookies != undefined ? <Subscription /> : <LoginPage />} />
        <Route path="/checkout" element={cookies != undefined ? <Checkout /> : <LoginPage />} />
        <Route path='/updateUserCoverLetter' element={cookies != undefined ? <UpdateUserCoverLetter /> : <LoginPage />} />
      </Routes >
      <Footer />
      <Helmet>

        <script src="assets/js/main.js"></script>

      </Helmet>
    </>
  );
}

export default App;

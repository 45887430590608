import React, { useState, useEffect } from 'react';
import "../css/education.css";
import Validate from '../../validation';
import axios from "axios";
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { Aisuggesstionmodal } from '../modal/Aimodal';
import { apiUrl, aiAPIService, getCreditPoint, deductCreditPoints, getCreditPointDeducts, jwtVerify } from "../../function";
const vld = new Validate
export default function Education({ onEducationChange }) {
  const [formValues, setFormValues] = useState([
    {
      heading: 'EDUCATION',
      school: '',
      courseName: '',
      specialization: '',
      gradingSystem: '',
      markes: '',
      courseType: '',
      date: '',
      gpa: '',
      descp: '',
    },
  ]);
  const [year, setYear] = useState('')
  const [courses, setCourse] = useState('');
  const [specialization, setSpecialization] = useState('')
  const [isModelButtonVisible, setIsModelButtonVisible] = useState(false);
  const [creditPoints, setCreditPoints] = useState(null);
  const [creditPointsDeduct, setCreditPointsDeduct] = useState(null);
  const [aiSuggesstion, setAiSuggesstion] = useState();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const handleChange = (e, i) => {
    const newFormValues = [...formValues];
    if ((e.target.name == "descp") && (e.target.value.length >= 20)) {
      setIsModelButtonVisible(true);
    }
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setIsModelButtonVisible(false);
    setFormValues([...formValues, { heading: 'EDUCATION', school: '', courseName: '', specialization: '', gradingSystem: '', markes: '', courseType: '', date: '', gpa: '', descp: '' }]);
  };

  const removeFormFields = (i) => {
    const newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    onEducationChange(newFormValues); // Notify parent about the change
  };
  async function courseName() {
    try {
      let response = await axios.get(apiUrl() + '/get-course-name');
      let course = response.data.course.map((item, index) => {
        return <option key={index}>{item.course_name}</option>
      })
      setCourse(course)
      let speci = response.data.speci.map((item, index) => {
        return <option key={index}>{item.speci_name}</option>
      })
      setSpecialization(speci)
    } catch (error) {

    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    onEducationChange(formValues);
    let token = Cookies.get('userAuth')
    try {
      const response = await axios.post(apiUrl() + '/submit-resume',
        { Education: formValues },
        { headers: { 'Authorization': `Bearer ${token}` } })
      if (response.data.statusCode == "200") {

      }
      else {

      }

    } catch (error) {

    }
  };
  async function getYM() {
    try {
      let response = await axios.get(apiUrl() + '/get-YM');
      let yearOption = response.data.Iyear.map((item, index) => {
        return <option key={index}>{item.year}</option>
      })
      setYear(yearOption)
    } catch (error) {

    }
  }

  const onOpenModal = (content, content_type) => {
    callAIApi(content, content_type);
    setOpen(true);
  }
  const onCloseModal = () => setOpen(false);
  const callAIApi = async (content, content_type) => {
    setIsLoading(true);
    let aiAPIRes = await aiAPIService(content, content_type);
    if (aiAPIRes) {
      setIsLoading(false)

      setAiSuggesstion(aiAPIRes);
    }
    else {
      setIsLoading(true);
    }
  }
  const getCreditP = async () => {
    let token = Cookies.get('userAuth')
    let creditPT = await getCreditPoint(token);

    if (creditPT) {
      setCreditPoints(creditPT);
    }
  }
  const getCreditPointDeduct = async () => {
    let creditPointDeduct = await getCreditPointDeducts();
    if (creditPointDeduct) {
      setCreditPointsDeduct(creditPointDeduct);
    }
  }
  const deductCreditPts = async (value) => {
    let token = Cookies.get('userAuth')
    if (creditPoints >= value) {
      let availCreditPoints = creditPoints - value;
      let updateCP = await deductCreditPoints(availCreditPoints, token);
      setCreditPoints(availCreditPoints);
      getCreditP();
    }
    else if (creditPoints == 0) {
      setCreditPoints(0);
    }
  };
  useEffect(() => {
    getYM()
    courseName()
    let x = localStorage.getItem("EDU" + jwtVerify("userAuth").userId);
    if (x != null) {
      x = JSON.parse(x);
      setFormValues(x)
      onEducationChange(x);
    }
    else {
      let usertoken = Cookies.get('userAuth')
      getUserEducations(usertoken);
    }
    // API call for getCreditPoint ----------------------
    getCreditP();
    getCreditPointDeduct();
  }, [])

  const getUserEducations = (usertoken) => {

    return axios.get(apiUrl() + `/get-users-education-detail`, { headers: { 'Authorization': `Bearer ${usertoken}` } })
      .then(response => {
        if (response.data.statusCode == "200") {
          setFormValues(response.data.userEducationData);
        } else {

        }
      })
      .catch(error => {
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="">
        {formValues.map((element, index) => (
          <div className="form-inline" key={index}>
            <section style={{ marginBottom: '10px' }} className="flex flex-col gap-3 rounded-md bg-white p-6 pt-4 shadow transition-opacity duration-200 transition-opacity duration-200 pb-6">
              <div className="flex items-center justify-between gap-4">
                <div className="flex grow items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6 text-gray-600">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"></path>
                  </svg>
                  <input
                    className="block w-full border-b border-transparent text-lg font-semibold tracking-wide text-gray-900 outline-none hover:border-gray-300 hover:shadow-sm focus:border-gray-300 focus:shadow-sm"
                    type="text"
                    name="heading"
                    value={element.heading || 'EDUCATION'}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
              </div>
              <div className="grid overflow-hidden transition-all duration-300 visible" style={{ gridTemplateRows: "1fr" }}>
                <div className="min-h-0">
                  <div className="row">
                    <label className="text-base font-medium text-gray-700 col-span-full ">Board Name/University
                      <input
                        className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                        type="text"
                        placeholder="Board Name/University"
                        name="school"
                        value={element.school || ""}
                        onChange={(e) => handleChange(e, index)}

                      />

                    </label>
                    <label className="text-base font-medium text-gray-700 col-span-full  mt-3">Course Name
                      <select
                        className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                        type="text"
                        name="courseName"
                        value={element.courseName || ""}
                        onChange={(e) => handleChange(e, index)}>
                        <option>Select Course</option>
                        {courses}
                      </select>

                    </label>
                    <label className="col-sm-4  mt-3">Specialization
                      <select
                        className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                        type="text"
                        name="specialization"
                        value={element.specialization || ""}
                        onChange={(e) => handleChange(e, index)}

                      >
                        <option>Select a Specialization</option>
                        {specialization}
                      </select>

                    </label>
                    <label className="col-sm-4  mt-3">Passing year
                      <select
                        className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                        type="text"
                        name="date"
                        value={element.date || ""}
                        onChange={(e) => handleChange(e, index)}
                      >
                        <option>Passing Year</option>
                        {year}
                      </select>

                    </label>
                    <label className="col-sm-4  mt-3">Grading System
                      <select
                        placeholder="Grading System"
                        className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                        type="text"
                        name="gradingSystem"
                        value={element.gradingSystem || ""}
                        onChange={(e) => handleChange(e, index)}

                      >
                        <option>Select Grading System</option>
                        <option> Scale 4 Grading System </option>
                        <option>% Marks of 100 Maximum </option>
                      </select>

                    </label>
                    <label className="text-base font-medium text-gray-700 col-span-full  mt-3">Marks
                      <input
                        className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                        type="text"
                        placeholder="eg.72"
                        name="markes"
                        value={element.markes || ""}
                        onChange={(e) => handleChange(e, index)}

                      />
                    </label>
                    <label className="text-base font-medium text-gray-700 col-span-full  mt-3">Course Type
                      <select
                        className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                        type="text"
                        name="courseType"
                        value={element.courseType || ""}
                        onChange={(e) => handleChange(e, index)}

                      >
                        <option>Select Course Type</option>
                        <option>Full Time</option>
                        <option>Part Time</option>
                        <option>Correspondence</option>
                      </select>

                    </label>

                    <div className="row">
                      <div className="col-sm-6">
                        <label className="text-base font-medium text-gray-700 col-span-full  mt-3">Description  </label>
                      </div>
                      <div className="col-sm-6">
                        {isModelButtonVisible ? (
                          creditPoints > 0 ? (
                            <a>
                              <button
                                type="button"
                                className="btn btn-link creds"
                                onClick={() => {
                                  onOpenModal(element.descp, 'education');
                                }}
                              >
                                View Suggestion
                              </button>
                            </a>
                          ) : (
                            // <label className="text-base font-medium text-gray-700 col-span-full mt-3">Get More Credit Point</label>
                            <Link to='/pricing' className="btn btn-link nocreds">
                              Get More Credit Point</Link>
                          )
                        ) : (
                          <p></p>
                        )}
                        {isModelButtonVisible ?
                          <Aisuggesstionmodal
                            isLoading={isLoading}
                            open={open}
                            aiSuggesstion={aiSuggesstion}
                            handlechangeAitext={handleChange}
                            closeModal={onCloseModal}
                            ondeductCreditPts={deductCreditPts}
                            for={"descp"}
                            indexNum={index}
                          />
                          : ''}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <textarea
                          placeholder="Description"
                          className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                          type="text"
                          style={{ height: "88px" }}
                          name="descp"
                          value={element.descp || ""}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </div>
                    </div>

                    <div className="absolute right-0 top-0 flex gap-0.5 ">
                      <div className="transition-all duration-300 invisible opacity-0 -mr-6">
                        <span>
                          <button type="button" className="rounded-full outline-none hover:bg-gray-100 focus-visible:bg-gray-100 p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-4 w-4 text-gray-400">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 19.5v-15m0 0l-6.75 6.75M12 4.5l6.75 6.75"></path>
                            </svg>
                            <span className="sr-only">Move up</span>
                          </button>
                        </span>
                      </div>
                      <div className="transition-all duration-300 invisible opacity-0">
                        <span>
                          <button type="button" className="rounded-full outline-none hover:bg-gray-100 focus-visible:bg-gray-100 p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-4 w-4 text-gray-400">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"></path>
                            </svg>
                            <span className="sr-only">Move down</span>
                          </button>
                        </span>
                      </div>
                      <div className="transition-all duration-300 invisible opacity-0">
                        <span>
                          <button type="button" className="rounded-full outline-none hover:bg-gray-100 focus-visible:bg-gray-100 p-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-4 w-4 text-gray-400">
                              <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0">
                              </path>
                            </svg>
                            <span className="sr-only">Delete job</span>
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2 flex justify-end">
                {index ? (
                  <button type="button" onClick={() => removeFormFields(index)}>
                    <i className="bi bi-trash3-fill"></i>
                  </button>
                ) : null}
              </div>
            </section>
            <div className="mt-2 flex justify-end">
              <button type="submit" className="btn-space flex items-center rounded-md bg-white py-2 pl-3 pr-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"></path>
                </svg>
                Save
              </button>
              <button type="button" onClick={() => addFormFields()} className="flex items-center rounded-md bg-white py-2 pl-3 pr-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"></path>
                </svg>
                Add Education
              </button>
            </div>
          </div>
        ))}
      </form>
    </>
  );
}

import "./css/contact.css";
import axios from "axios";
import { useState } from "react";
import { apiUrl,recaptchaKey } from "../function";
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import jsPDF from 'jspdf';

export default function Contact() {
  const [msg, setMsg] = useState('');
  const [inputValues, setInputValues] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });
  const recaptchaRef = useRef(null);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMsg('');

    // Verify reCAPTCHA
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setMsg('Please complete the reCAPTCHA.');
      return;
    }

    try {
      const response = await axios.post(apiUrl() + '/contact_us_message', {
        inputValues,
      });
      if (response.data.statusCode === '200') {
        setMsg(response.data.status);
      } else {
        setMsg(response.data.status);
      }
    } catch (error) {

    }
  };
  return (
    <section id="contact" className="contact">



      <div className="container" data-aos="fade-up">

        <header className="section-header">
          {/* <h2>Contact</h2>
            <p>Contact Us</p> */}
        </header>

        <div className="row gy-4">

          <div className="col-lg-6">

            <div className="row gy-4">
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-geo-alt"></i>
                  <h3>Address</h3>
                  <p></p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-telephone"></i>
                  <h3>Call Us</h3>
                  <p>+91 94242-82486</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@thejobsearch.in</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="info-box">
                  <i className="bi bi-clock"></i>
                  <h3>Open Hours</h3>
                  <p>Monday - Friday 9:00AM - 05:00PM</p>
                </div>
              </div>
            </div>

          </div>

          <div className="col-lg-6">
            <form className="php-email-form" onSubmit={handleSubmit}>
              <div className="row gy-4">

                <div className="col-md-6">
                  <input type="text" name="name" className="form-control" placeholder="Your Name" required
                    value={inputValues.name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-6 ">
                  <input type="email" className="form-control" name="email" placeholder="Your Email" required
                    value={inputValues.email}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-12">
                  <input type="text" className="form-control" name="subject" placeholder="Subject" required
                    value={inputValues.subject}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="col-md-12">
                  <textarea className="form-control" name="message" rows="6" placeholder="Message" required
                    value={inputValues.message}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
                <div className="col-md-12 text-center">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={recaptchaKey()}
                    onChange={() => { }}
                  />
                </div>
                <div className="col-md-12 text-center">
                  <div className="">{msg}</div>
                  <button type="submit">Send Message</button>
                </div>

              </div>
            </form>

          </div>

        </div>

      </div>

    </section>
  )
}
import "./css/hero.css";
import { Link } from "react-router-dom";
export default function hero() {
    return (
        <section id="hero" className="hero d-flex align-items-center">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column justify-content-center">
                        <h1 style={{ fontSize: '35px' }} data-aos="fade-up">Increase Your Job Prospects with a Perfectly Crafted Resume! with Intelligent Design and Content Enhancement</h1>

                        <h2 data-aos="fade-up" data-aos-delay="400">Your Path to Career Success</h2>

                        <div data-aos="fade-up" data-aos-delay="800" >
                            <div className="text-center text-lg-start">
                                <Link to="/rdesign" className="btn-get-started  d-inline-flex align-items-center justify-content-center align-self-center">
                                    <span> “Create Resume Now” 100% Free</span>
                                    <i className="bi bi-arrow-right"></i>
                                </Link>

                            </div>
                            <div className="text-center text-lg-start">
                                <Link to="/coverLetter" className="btn-get-started  d-inline-flex align-items-center justify-content-center align-self-center">
                                    <span> Write Cover Letter</span>
                                    <i className="bi bi-arrow-right"></i>
                                </Link>

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 hero-img " data-aos="fade-up" >
                        <img src="assets/img/hero-img.png" className="img-fluid" alt="" />
                    </div>
                </div>
            </div>

        </section>


    )
}

import React, { useState } from 'react';
import Sidebarmenue from '../components/sidebar';
import Viewresume from './viewresume';
import { Link } from 'react-router-dom';
export default function Dashboard() {

  return (
    <div style={{ display: 'flex', height: '100%', minHeight: '400px', marginTop: "100px" }}>

      <Sidebarmenue />
      <div style={{ width: "80%", height: "100vh", backgroundColor: "white", boxShadow: "0 3px 7px rgba(0, 0, 0, 0.2)", display: "flex", flexDirection: "column", alignItems: "center" }} className="container">
        <Viewresume />

      </div>

    </div>
  );
};
import Sidebarmenue from "../components/sidebar"
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from "../function";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
var base64 = require('base-64');
export default function MyDetails() {

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [profilePic, setprofilePic] = useState('https://img.icons8.com/color/120/user.png')
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [mode, setMode] = useState(0);
  const [visibility, setVisibility] = useState(false);
  const [msg, setMsg] = useState('')
  const [successMsg, setsuccessMsg] = useState('')
  const [profilePhoto, setProfilePhoto] = useState('');
  const handlenameChange = (e) => {
    setName(e.target.value);

  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleFileChange = (event) => {
    let file = event.target.files[0];
    setProfilePhoto(file);
    if (file) {
      previewFile(file);
    }
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setprofilePic(event.target.result);
    };
    reader.readAsDataURL(file);
  };

  const getuserDetails = async () => {
    try {
      let authToken = Cookies.get('userAuth');
      let headers = {
        Authorization: `Bearer ${authToken}`,
      };
      let response = await axios.get(apiUrl() + '/mydetails', { headers });
      if (response.data.statusCode == 200) {
        if (response.data.userData[0].profile_pic != null) {
          setprofilePic(response.data.userData[0].profile_pic)
          setProfilePhoto(response.data.userData[0].profile_pic)
        }
        if (response.data.userData[0].mode == 1) {
          setVisibility(true)
        }
        setName(response.data.userData[0].user_name)
        setMode(response.data.userData[0].mode)
      }
      else {

      }
    } catch (error) {
      console.error(error);
    }

  };
  const heandelUpdate = async (event) => {
    let formData = new FormData();
    formData.append('profilePic', profilePhoto);
    formData.append('name', name);
    let authToken = Cookies.get('userAuth');
    if (mode == 0) {
      if (name == '') {
        setMsg('All field are required!')
      }
      else {
        try {
          const response = await axios.post(apiUrl() + '/update-profile',
            formData,
            { headers: { 'Authorization': `Bearer ${authToken}` } })
          if (response.data.statusCode == "200") {
            Cookies.set('userAuth', response.data.token, { expires: 365 });
            setMsg('')
            setsuccessMsg('Profile update successful!')
          } else {
            setsuccessMsg(' ')
            setMsg('You entered wrong password!')
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    else if (mode == 1) {
      formData.append('password', password);
      if (password == '' || name == '') {
        setMsg('All field are required!')
      } else {
        try {
          const response = await axios.post(apiUrl() + '/update-profile',
            formData,
            { headers: { 'Authorization': `Bearer ${authToken}` } })
          if (response.data.statusCode == "200") {
            Cookies.set('userAuth', response.data.token, { expires: 365 });
            setMsg('')
            setsuccessMsg('Profile update successful!')
          } else {
            setsuccessMsg(' ')
            setMsg('You entered wrong password!')
          }
        } catch (error) {
          console.error(error);
        }
      }
    }

  }
  useEffect(() => {
    getuserDetails()
  }, [])
  return (
    <>
      <div style={{ display: 'flex', height: '100%', minHeight: '400px', marginTop: "100px" }}>
        <Sidebarmenue />

        <div style={{ width: "80%", height: "100vh", backgroundColor: "white", boxShadow: "0 3px 7px rgba(0, 0, 0, 0.2)", display: "flex", flexDirection: "column", alignItems: "center" }} className="container">
          <h4 style={{ fontSize: "25px", fontFamily: "Verdana", margin: "20px", fontWeight: "bolder", color: "#0D3779" }}>UPDATE PROFILE</h4>


          <form style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
            <label htmlFor="profile"> <img src={profilePic} width={80} height={80} style={{ border: "1px solid gray", borderRadius: "3px" }}></img></label>
            <input onChange={handleFileChange} style={{ display: 'none' }} type="file" name="profile" id="profile" />
            <TextField sx={{ mb: 1, width: '25ch' }} id="standard-basic" label="Full Name" name="name" variant="standard" value={name} onChange={handlenameChange} />

            <FormControl sx={{ mb: 1, width: '25ch' }} variant="standard">
              <div style={{ display: visibility ? 'block' : 'none' }}>
                <InputLabel htmlFor="standard-adornment-password">Enter Password</InputLabel>
                <Input
                  id="standard-adornment-password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </div>
            </FormControl><br></br>
            <span className="text-success">{successMsg}</span>
            <span className="text-danger">{msg}</span>
            <Button onClick={heandelUpdate} type="button" variant="contained" style={{ padding: "10px", width: "25ch" }}>UPDATE</Button>

          </form>


        </div>
      </div>

    </>
  )
}
import "../css/login.css";
import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import axios from "axios";
import Cookies from 'js-cookie';
import { apiUrl } from "../function";
import { useNavigate, Link, useParams } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';


export default function Changepassword() {

  var urlParams = new URLSearchParams(window.location.search);
  var token = urlParams.get('validationToken');
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [msg, setMsg] = useState('');
  const [validationStatus, setValidationStatus] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const [inputValues, setInputValues] = useState({
    password: '',
    confirmpassword: ''
  });

  //const { token } = useParams();



  useEffect(() => {

    verifyEmail(token);
  });


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const verifyEmail = async (token) => {

    try {
      const response = await axios.get(apiUrl() + `/validate-email/${token}`)
      if (response.status == "200") {

        setValidationStatus(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValues.confirmpassword != '' && inputValues.password != '') {

      if (inputValues.confirmpassword === inputValues.password) {
        setMsg('')
        try {
          const response = await axios.post(apiUrl() + '/change-password', {
            token,
            inputValues
          })
          if (response.status == "200") {
            //Cookies.set('userAuth', response.data.Token, { expires: 365 });
            alert(' Password changed successfully!!!');
            navigate(`/`, { replace: true });
            // window.location.reload()
          }
          else {
            setMsg(response.data.status)
          }

        } catch (error) {
          console.error(error);
        }
      }
      else {
        alert('password and confirm did not match ');

      }

    }
    else {
      setMsg('All field are required !')
    }
  };

  return (


    <div>
      <div className="login">

        <div className="login-container">
          <h1>Create Password</h1>
          <form onSubmit={handleSubmit}>
            <FormControl sx={{ mb: 1, width: '25ch' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">Enter Password</InputLabel>
              <Input
                id="standard-adornment-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                onChange={handleInputChange}
                value={inputValues.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                } />
            </FormControl><br /><br />
            <FormControl sx={{ mb: 1, width: '25ch' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">Re-Enter Password</InputLabel>
              <Input
                id="standard-adornment-password"
                name="confirmpassword"
                type={showPassword ? 'text' : 'password'}
                onChange={handleInputChange}
                value={inputValues.confirmpassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                } />
            </FormControl><br />

            <span className="text-danger">{msg}</span><br />
            <Button type="submit" variant="contained" style={{ padding: "10px", width: "25ch" }}>Change Password</Button>
          </form>
        </div>

      </div>

    </div>




  );
};
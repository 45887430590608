import "./css/testimonials.css";
export default function Testimoinal() {
  return (
    <section id="" className="testimonials" style={{ marginTop: "30px" }}>

      <div className="container" data-aos="">

        <header className="section-header">
          <h2>Testimonials</h2>
          <p>What they are saying about us</p>
        </header>

        <div className="testimonials-slider swiper" data-aos="" data-aos-delay="200">
          <p>Certainly, here are 10 user reviews with slightly longer text, written in a style that may resonate with students and early job seekers:</p>
          <div className="swiper-wrapper">

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  As a recent grad, this tool gave my resume the professional edge I needed to stand out in a competitive job market. It's a lifesaver!
                </p>
                <div className="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt="" />
                  <h3>Amit Khare</h3>
                  <h4>Student</h4>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  I'm in awe of how user-friendly this resume builder is. It helped me craft a winning resume that got me noticed by recruiters.
                </p>
                <div className="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt="" />
                  <h3>Pooja Sharma</h3>
                  <h4>Designer</h4>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  Being a student, I was clueless about resume writing. This tool not only simplified the process but also made me more confident in my job search.                  </p>
                <div className="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt="" />
                  <h3>Shreya Goel</h3>
                  <h4>Freelancer</h4>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  I can't thank this platform enough. It transformed my amateur resume into a polished document that landed me interviews and job offers.                  </p>
                <div className="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt="" />
                  <h3>Utkarsh Chaddha</h3>
                  <h4>Freelancer</h4>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  For someone just starting their career, this resume builder is pure gold. It guided me step by step, resulting in a resume I'm proud of.                  </p>
                <div className="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-5.jpg" className="testimonial-img" alt="" />
                  <h3>Farukh Sheikh</h3>
                  <h4>Entrepreneur</h4>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  The AI suggestions were a game-changer. They made my resume look like it was crafted by a pro, and I got my first job interview!
                </p>
                <div className="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-6.jpg" className="testimonial-img" alt="" />
                  <h3>Kapil Tiwari</h3>
                  <h4>Graphic Designer</h4>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  I used to dread resume writing, but this tool made it a breeze. Now I feel prepared and confident in my job search.                  </p>
                <div className="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-7.jpg" className="testimonial-img" alt="" />
                  <h3>Manisha Rathore</h3>
                  <h4>Full-Stack Developer</h4>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  This resume builder is perfect for students like me. It simplified the process and helped me secure internships and part-time gigs.                  </p>
                <div className="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-8.jpg" className="testimonial-img" alt="" />
                  <h3>Dipesh Jain</h3>
                  <h4>Data Scientiest</h4>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  I'm new to job hunting, but this tool made me look like a pro. It's an essential resource for anyone starting their career journey.                  </p>
                <div className="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-9.jpg" className="testimonial-img" alt="" />
                  <h3>Shweta Sharma</h3>
                  <h4>Senior Sales Executive</h4>
                </div>
              </div>
            </div>

            <div className="swiper-slide">
              <div className="testimonial-item">
                <div className="stars">
                  <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                </div>
                <p>
                  I recommend this to every college student. It took my resume from ordinary to extraordinary, and I landed my dream internship!                  </p>
                <div className="profile mt-auto">
                  <img src="assets/img/testimonials/testimonials-10.jpg" className="testimonial-img" alt="" />
                  <h3>Bipasha Banerjee</h3>
                  <h4>Analyst</h4>
                </div>
              </div>
            </div>


          </div>
          <div className="swiper-pagination"></div>
        </div>

      </div>

    </section>
  )
}
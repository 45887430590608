import { Link, useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { Modal } from 'react-responsive-modal';
import axios from "axios";
import Cookies from "js-cookie";
import { apiUrl } from "../function";
export default function Rdesign() {
  const [open, setOpen] = useState(false);
  const [cookies, setCookie] = useState(undefined)
  const [templates, setTemplates] = useState('')
  const onCloseModal = () => setOpen(false);
  let [flag, setFlag] = useState(0)
  let [msg, setMsg] = useState(false)
  const navigate = useNavigate();
  const handleLoadMore = () => {
    flag = flag + 6
    setFlag(flag)
    getTemplates()
  }
  const handleNavigate = async (tmp_number) => {
    try {
      let authToken = Cookies.get('userAuth');
      let headers = {
        Authorization: `Bearer ${authToken}`,
      };
      let response = await axios.get(apiUrl() + '/conform-tmp?tmp_num=' + tmp_number, { headers });
      if (response.data.statusCode == 200) {
        navigate(`/buildresume?resume=${tmp_number}`);
      }
      else {
        setOpen(true);
      }
    } catch (error) {
      console.error(error);
    }

  };
  async function getTemplates() {
    try {
      let authToken = Cookies.get('userAuth');
      let headers = {
        Authorization: `Bearer ${authToken}`,
      };
      let response = await axios.get(apiUrl() + '/get-templates?flag=' + flag, { headers });

      if (response.data.statusCode === 200) {

        let yearOption = response.data.templateMasterData.map((item, index) => {
          return (
            <div key={index} className="col-lg-4 col-md-6 portfolio-item filter-app">
              <div className="portfolio-wrap" style={{ border: "1px solid black", position: "relative" }}>
                <img style={{ height: '500px' }} src={apiUrl() + '/' + item.image} className="img-fluid" alt="" />
                <div className="portfolio-info">
                  <div className="portfolio-links">
                    <button onClick={() => handleNavigate(item.template_number)} value={item.template_number} title="Select">
                      <i style={{ fontSize: '35px' }} className="bi bi-link"></i>
                    </button>
                  </div>
                </div>
                {item.type == 0 ? '' :
                  <div style={{ position: "absolute", bottom: 0, right: 0, background: "rgba(255, 255, 255, 0.8)", padding: "5px" }}>
                    <label><img src="https://img.icons8.com/color/48/warranty-card.png" alt="warranty-card" /></label>
                  </div>
                }
              </div>
            </div>

          );
        });

        setTemplates(yearOption);
      } else {
        setMsg('No more resume found');
      }
    } catch (error) {
      console.error(error);
    }
  }
  useEffect(() => {

    getTemplates()

  }, [])
  return (
    <section id="portfolio" className="portfolio" style={{ marginTop: "70px", padding: "5px" }}>
      <div className="container" data-aos="fade-up">
        <div className="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
          {templates}
        </div>
        <div className="text-center">
          <span style={{ color: 'red' }}>{msg}</span><br />
          <button className="btn-btn-blue" onClick={handleLoadMore}>More</button>
        </div>
      </div>
      <Modal open={open} onClose={onCloseModal} showCloseIcon='false' closeIcon=' ' classNames={{
        overlayAnimationIn: 'customEnterOverlayAnimation',
        overlayAnimationOut: 'customLeaveOverlayAnimation',
        modalAnimationIn: 'customEnterModalAnimation',
        modalAnimationOut: 'customLeaveModalAnimation',
      }}
        animationDuration={800}
        center >
        <Link to="/pricing" className="btn-btn-blue ">Buy Now</Link>
      </Modal>
    </section>
  );
}
import "../css/bulidresume.css";
import { useState } from "react";
import Resumeview from "../resumeTemplates/resumeView";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { jwtVerify } from "../function";
import Cookies from "js-cookie";
export default function Viewresume() {
  var paramValue = localStorage.getItem('resumeId')
  const [personalInfoData, setPersonalInfoData] = useState({});
  const [eduData, setEduData] = useState('');
  const [workExpData, setworkExpData] = useState({});
  const [projectData, setprojectData] = useState('');
  const [skillSet, setskillSet] = useState('');
  const [socialLinks, setsocialLinks] = useState('');
  const [languages, setlanguages] = useState('');
  const [hobbies, sethobbies] = useState('');
  const [certifications, setCertification] = useState('')
  const [techskill, setTechskill] = useState('')
  const [OtherPersonalDetails, setOtherPersonalDetailsChange] = useState('')
  const [ExtracurricularActivities, setExtracurricularActivities] = useState('')
  const [CustomSection, setCustomSection] = useState('')


  useEffect(() => {
    console.log(paramValue)
    let Personaldetails = localStorage.getItem("PD" + jwtVerify("userAuth").userId);

    let inputValue = JSON.parse(Personaldetails)
    setPersonalInfoData({ inputValue })
    let Workexpe = localStorage.getItem("WXP" + jwtVerify("userAuth").userId);
    let data = JSON.parse(Workexpe)
    setworkExpData({ data })
  }, [])


  const handlePersonaldetailsChange = (inputValue) => {
    localStorage.setItem("PD" + jwtVerify("userAuth").userId, JSON.stringify(inputValue));
    setPersonalInfoData((prevInputData) => ({
      ...prevInputData,
      inputValue,
    }));

  };


  const handleWorkexpeChange = (data) => {
    localStorage.setItem("WXP" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setworkExpData((prevInputData) => ({
      ...prevInputData, data,
    }));
  };


  const handleEducationChange = (data) => {
    localStorage.setItem("EDU" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setEduData((prevInputData) => ({
      ...prevInputData, data,
    }));
  };

  const handleProjectChange = (data) => {
    localStorage.setItem("PRJ" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setprojectData((prevInputData) => ({
      ...prevInputData, data,
    }));
  };



  const handleSkillChange = (data) => {
    localStorage.setItem("SKL" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setskillSet((prevInputData) => ({
      ...prevInputData, data,
    }));
  };

  const handleSocialChange = (data) => {
    localStorage.setItem("SLNk" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setsocialLinks((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleLanguageChange = (data) => {
    localStorage.setItem("LNG" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setlanguages((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleHobbiesChange = (data) => {
    localStorage.setItem("HOB" + jwtVerify("userAuth").userId, JSON.stringify(data));
    sethobbies((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleCertificationChange = (data) => {
    localStorage.setItem("CRTF" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setCertification((prevInputData) => ({
      ...prevInputData, data,
    }));
  };

  const handelTechSkillChange = (data) => {
    localStorage.setItem("TECHSKL" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setTechskill((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleOtherPersonalDetailsChange = (data) => {
    localStorage.setItem("OTPD" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setOtherPersonalDetailsChange((prevInputData) => ({
      ...prevInputData, data,
    }));
  };

  const handlExtracurricularActivitiesChange = (data) => {
    localStorage.setItem("Extracurricular" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setExtracurricularActivities((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handelCustomSection = (data) => {
    localStorage.setItem("CSTSEC" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setCustomSection((prevInputData) => ({
      ...prevInputData, data,
    }));
  };

  return (
    <>
      <Link to={"/buildresume?resume=" + paramValue} className="btn-btn-blue">Edit Resume</Link>
      <Resumeview resume={paramValue}
        personalInfoData={personalInfoData}
        workExpData={workExpData}
        eduData={eduData}
        projectData={projectData}
        skillSet={skillSet}
        socialLinks={socialLinks}
        languages={languages}
        hobbies={hobbies}
        certifications={certifications}
        techskill={techskill}
        OtherPersonalDetails={OtherPersonalDetails}
        ExtracurricularActivities={ExtracurricularActivities}
        CustomSection={CustomSection}
      />

    </>
  )
}
let arr = []
class Validate {

  required(value, field, msg, i = 0) {
    let errors = {}
    if (!value)
      errors[field] = msg;
    arr[i] = errors
    console.log("arr",arr)
    return arr
  }

}
  export default Validate
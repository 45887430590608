import "../css/Register.css";
import { recaptchaKey } from "../function";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from "../function";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import jwtDecode from 'jwt-decode';

export default function Register() {
  const recaptchaRef = useRef(null);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (Cookies.get('userAuth') != undefined) {
      navigate(`/`, { replace: true });
    }
  }, [])
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('')
  const handlenameChange = (e) => {
    setName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };


  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Verify reCAPTCHA
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setMsg('Please complete the reCAPTCHA.');
      return;
    }
    if (name != '' && email != '' && password != '') {
      try {
        const response = await axios.post(apiUrl() + '/register', {
          name,
          email,
          picture: '',
          password
        })
        if (response.data.statusCode == "200") {
          Cookies.set('userAuth', response.data.token, { expires: 365 });
          window.location.reload()
        }
        else {
          setMsg(response.data.status)
        }

      } catch (error) {
        console.error(error);
      }
    }
    else {
      setMsg('All field required !')
    }

  };
  //google Login..............................................
  const handleLogin = async (credentialResponse) => {
    var obj = jwtDecode(credentialResponse.credential);
    if (obj.email_verified) {

      try {
        const response = await axios.post(apiUrl() + '/register-google-login', {
          name: obj.name,
          email: obj.email,
          picture: obj.picture,
          password: ''
        })

        if (response.data.statusCode == "200") {

          Cookies.set('userAuth', response.data.token, { expires: 365 });

          window.location.reload()
        }
      } catch (error) {
        console.error(error);
      }
    }

  }
  return (
    <div>
      <div className="register">
        <div className="register-container">
          <h1 className="main-headline">SIGN UP</h1>
          <h2 className="sub-headline"> Create a FREE Account to Download your Document </h2>

          <form onSubmit={handleSubmit} >

            <TextField sx={{ mb: 1, width: '25ch' }} id="standard-basic" label="Full Name" name="name" variant="standard" value={name} onChange={handlenameChange} />
            <TextField sx={{ mb: 1, width: '25ch' }} id="standard-basic" type="email" label="Email" name="email" variant="standard" value={email} onChange={handleEmailChange} />
            <FormControl sx={{ mb: 1, width: '25ch' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                id="standard-adornment-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl><br></br>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={recaptchaKey()}
              onChange={() => { }}
            />
            <span className="text-danger">{msg}</span><br></br>
            <Button type="submit" variant="contained" style={{ padding: "10px", width: "25ch" }}>Register</Button>

          </form>
          <div className="or-divider">
            <span>Or</span>
          </div>
          <div className="google-login" style={{ display: 'flex', justifyContent: 'center' }}>
            <GoogleOAuthProvider clientId="923492471250-tqdrgh8urq1qefcf5c2u552qsojfe9r6.apps.googleusercontent.com"  >
              <GoogleLogin onSuccess={handleLogin} />
            </GoogleOAuthProvider>
          </div>
        </div>
      </div>



    </div>

  );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl, aiAPIService, getCreditPoint, deductCreditPoints, getCreditPointDeducts, } from "../function";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Sidebarmenue from "../components/sidebar"
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import '../css/updateUserCoverLetter.css'
import { Aisuggesstionmodal } from '../components/modal/Aimodal';
export default function UpdateUserCoverLetter() {

  const [coverPageData, setCoverPageData] = useState('');
  const [content, setContent] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [isModelButtonVisible, setIsModelButtonVisible] = useState(false);
  const [creditPoints, setCreditPoints] = useState(null);
  const [creditPointsDeduct, setCreditPointsDeduct] = useState(null);
  const [aiSuggesstion, setAiSuggesstion] = useState();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  var urlParams = new URLSearchParams(window.location.search);
  var coverPageId = urlParams.get('coverPageId');


  const getuserCoverLetterByID = async (coverPageId) => {
    let tmpArray = [];

    try {
      let authToken = Cookies.get('userAuth');
      let headers = {
        Authorization: `Bearer ${authToken}`,
      };

      let response = await axios.get(apiUrl() + `/get-user-cover-by-id/${coverPageId}`, { headers });
      if (response.data.statusCode == 200) {
        setCoverPageData(response.data.userData[0].cover_page)
        setCompanyName(response.data.userData[0].company_name)
      }
    } catch (error) {
      console.error(error);
    }


  }

  const handleProcedureContentChange = (value) => {
    setCoverPageData(value);
    if (value.length >= 20) {
      setIsModelButtonVisible(true);
    }
    else {
      setIsModelButtonVisible(false);
    }
  };

  const handleChanges = (event) => {
    setCompanyName(event.target.value);
  };

  const handleProcedureAiContentChange = (e) => {
    setCoverPageData(e.target.value);
  };
  const handleSaveClick = async () => {
    let token = Cookies.get('userAuth')
    const formData = {
      coverPageData: coverPageData,
      companyName: companyName
    };

    try {
      const response = await axios.post(apiUrl() + `/update-user-cover-page-by-id/${coverPageId}`,
        formData,
        { headers: { 'Authorization': `Bearer ${token}` } })
      if (response.data.statusCode == "200") {
        alert('user cover page updated successfully!!!');

      } else {
      }
    } catch (error) {
      console.error(error);
    }
  }

  const onOpenModal = (content, content_type) => {
    callAIApi(content, content_type);
    setOpen(true);
  }
  const onCloseModal = () => setOpen(false);
  const callAIApi = async (content, content_type) => {
    setIsLoading(true);
    let aiAPIRes = await aiAPIService(content, content_type);
    if (aiAPIRes) {
      setIsLoading(false)

      setAiSuggesstion(aiAPIRes);
    }
    else {
      setIsLoading(true);
    }
  }
  const getCreditP = async () => {
    let token = Cookies.get('userAuth')
    let creditPT = await getCreditPoint(token);
    if (creditPT) {
      setCreditPoints(creditPT);
    }
  }
  const getCreditPointDeduct = async () => {
    let creditPointDeduct = await getCreditPointDeducts();
    if (creditPointDeduct) {
      setCreditPointsDeduct(creditPointDeduct);
    }
  }
  const deductCreditPts = async (value) => {
    let token = Cookies.get('userAuth')
    if (creditPoints >= value) {
      let availCreditPoints = creditPoints - value;
      let updateCP = await deductCreditPoints(availCreditPoints, token);
      setCreditPoints(availCreditPoints);
      getCreditP();
    }
    else if (creditPoints == 0) {
      setCreditPoints(0);
    }
  };

  useEffect(() => {

    getuserCoverLetterByID(coverPageId);
    // API call for getCreditPoint ----------------------
    getCreditP();
    getCreditPointDeduct();
  }, [])
  return (
    <div className=' usrcvr'>
      <Sidebarmenue />
      <div className='row' style={{ width: "70%", margin: "auto" }} >
        <div className="row">
          <div className="col-sm-12" >
            <Link to='/coverLetter' >
              <button className='create_cover_letter_btn' >Create A New Cover Letter</button>
            </Link>

          </div>
        </div>
        <div className="row" style={{ textAlign: 'center', marginTop: '15px' }}>
          <div className="col-sm-2 " style={{ padding: "8px" }}>
            Company Name
          </div>
          <div className="col-sm-4" style={{ margin: 'auto' }}>
            <input
              name="companyName"
              defaultValue={companyName}
              onChange={handleChanges}
              type="text" class="form-control" id="companyName"
              placeholder="Enter Company Name" />
          </div>
          <div className="col-sm-2" style={{ textAlign: 'center', margin: 'auto' }}>
            <button className='edtr_btn' onClick={handleSaveClick}>Save</button>
          </div>
          <div className="col-sm-4" style={{ padding: "0.75 rem" }}>
            {isModelButtonVisible ? (
              creditPoints > 0 ? (
                <a>
                  <button
                    type="button"
                    className="btn btn-link "
                    onClick={() => {
                      onOpenModal(coverPageData, 'cover latter');
                    }}
                  >
                    View Suggestion
                  </button>
                </a>
              ) : (
                // <label className="text-base font-medium text-gray-700 col-span-full mt-3">Get More Credit Point</label>
                <Link to='/pricing' className="btn  btn-link">
                  Get More Credit Point</Link>
              )
            ) : (
              <p></p>
            )}

            {isModelButtonVisible ?
              <Aisuggesstionmodal
                isLoading={isLoading}
                open={open}
                aiSuggesstion={aiSuggesstion}
                handlechangeAitext={handleProcedureAiContentChange}
                closeModal={onCloseModal}
                ondeductCreditPts={deductCreditPts}
                for={"descp"}
              />
              : ''}
          </div>
        </div>
        <div className="row " style={{}}>
          <div className="col-sm-12">
            <ReactQuill
              theme="snow"
              value={coverPageData}
              onChange={handleProcedureContentChange}
              style={{ height: '80%', width: '90%', margin: '20px 0px 40px' }}
            />
          </div>
        </div>
      </div>
    </div>

  )
}
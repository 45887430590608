import { useState, useEffect } from "react";
import axios from "axios";
import Cookies from 'js-cookie';
import { apiUrl, jwtVerify, langValidation } from '../../function';
import "../css/language.css";

export default function Language({ onLanguageChange }) {


    const [isValid, setisValid] = useState(false)
    const [formValues, setFormValues] = useState([{ heading: 'LANGUAGE', language: '' }]);


    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        if (!langValidation(e.target.value) && e.target.name == 'language') {
            setisValid(false)
            formValues[i].errorMsg = "Please enter valid Language First later must be Capital later!"
        }
        else {
            formValues[i].errorMsg = " "
            setisValid(true)
        }
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setFormValues([...formValues, { heading: 'LANGUAGE', language: '' }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues);
        onLanguageChange(newFormValues);
    }

    let handleSubmit = async (event) => {
        event.preventDefault();
        if (!isValid) {
            return;
        }
        onLanguageChange(formValues);
        let token = Cookies.get('userAuth')
        try {
            const response = await axios.post(apiUrl() + '/submit-resume',
                { Language: formValues },
                { headers: { 'Authorization': `Bearer ${token}` } })
            if (response.data.statusCode == "200") {

            }
            else {

            }

        } catch (error) {

        }
    }
    useEffect(() => {
        let x = localStorage.getItem("LNG", +jwtVerify("userAuth").userId);
        if (x != null) {
            x = JSON.parse(x);
            setFormValues(x)
        }
        else {
            let usertoken = Cookies.get('userAuth')
            getUserLanguageData(usertoken);
        }
    }, [])

    const getUserLanguageData = (usertoken) => {
        return axios.get(apiUrl() + `/get-users-language-detail`, { headers: { 'Authorization': `Bearer ${usertoken}` } })
            .then(response => {

                if (response.data.statusCode == "200") {
                    setFormValues(response.data.userLanguageData);
                } else {

                }
            })
            .catch(error => {

            });
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="">
                {formValues.map((element, index) => (

                    <div className="form-inline" key={index}>
                        <section style={{ marginBottom: '10px' }} className="flex flex-col gap-3 rounded-md bg-white p-6 pt-4 shadow transition-opacity duration-200 transition-opacity duration-200 pb-6">
                            <div className="flex items-center justify-between gap-4">
                                <div className="flex grow items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                    aria-hidden="true" className="h-6 w-6 text-gray-600">
                                    <path strokeLinecap="round" strokeLinejoin="round"
                                        d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21">
                                    </path>
                                </svg>


                                    <input
                                        className="block w-full border-b border-transparent text-lg font-semibold tracking-wide text-gray-900 outline-none hover:border-gray-300 hover:shadow-sm focus:border-gray-300 focus:shadow-sm"
                                        type="text"
                                        name="heading" value={element.heading || 'SOCIAL LINK'} onChange={e => handleChange(index, e)}

                                    />


                                </div>
                                {/* <div className="flex items-center gap-0.5"><span><button type="button"
                                    className="rounded-full outline-none hover:bg-gray-100 focus-visible:bg-gray-100 p-1.5"><svg
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                        strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                                        className="h-6 w-6 text-gray-400">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M12 4.5v15m0 0l6.75-6.75M12 19.5l-6.75-6.75"></path>
                                    </svg><span className="sr-only">Move down</span></button></span><span><button
                                        type="button"
                                        className="rounded-full outline-none hover:bg-gray-100 focus-visible:bg-gray-100 p-1.5"><svg
                                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                                            className="h-6 w-6 text-gray-400">
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z">
                                            </path>
                                            <path strokeLinecap="round" strokeLinejoin="round"
                                                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                                        </svg><span className="sr-only">Hide section</span></button></span>
                                </div> */}

                            </div>
                            <div className="grid overflow-hidden transition-all duration-300 visible"
                                style={{ gridTemplateRows: "1fr" }}>
                                <div className="min-h-0">
                                    <div className="relative grid grid-cols-6 gap-3"><label
                                        className="text-base font-medium text-gray-700 col-span-full">Language


                                        <input

                                            className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                            type="text"
                                            placeholder="language"
                                            name="language" value={element.language || ""} onChange={e => handleChange(index, e)}


                                        />


                                    </label>
                                    </div>
                                    <span className="text-danger mt-2 text-center">{element.errorMsg}</span>
                                </div>
                            </div>
                            <div className="mt-2 flex justify-end">

                            </div>

                        </section>

                        <div className="mt-2 flex justify-end">
                            {
                                index ?
                                    <button type="button" onClick={() => removeFormFields(index)}><i class="bi bi-trash3-fill"></i></button>
                                    : null
                            }

                        </div>



                    </div>
                ))}

                <div className="mt-2 flex justify-end">


                    <button
                        type="submit"
                        // onClick={handleSave}
                        className="btn-space flex items-center rounded-md bg-white py-2 pl-3 pr-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"><svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                            className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"></path>
                        </svg>Save</button>


                    <button type="button"
                        //onClick={() => handleAddField(fieldsetIndex)}
                        onClick={() => addFormFields()}
                        className="flex items-center rounded-md bg-white py-2 pl-3 pr-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"><svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                            className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"></path>
                        </svg>Add Language</button>
                </div>
            </form>


        </>
    )
}

import "../components/css/pricing.css";
import { useEffect, useState } from 'react';
import { apiUrl } from "../function";
import axios from "axios";
import { Link } from "react-router-dom";

export default function PricingSection() {

  const [priceListData, setPriceListData] = useState([]);

  useEffect(() => {
    getPackageMasterData()
  }, [])

  //GET Package  Data API Call
  const getPackageMasterData = async () => {
    try {
      const response = await axios.get(apiUrl() + '/get-package-master');

      if (response.data.statusCode == "200") {
        setPriceListData(...priceListData, response.data.packageListData);

      }
      else {

      }

    } catch (error) {

    }
  }

  return (
    <section id="pricing" className="pricing" style={{ marginTop: '112px' }}>

      <div className="container" data-aos="">

        <header className="section-header">
          <h2>Pricing</h2>
          <p>Check our Pricing</p>
        </header>

        <div className="row gy-4" data-aos="">
          {priceListData ? (
            priceListData.map((dataObj, index) => (
              <div key={index} className="col-lg-3 col-md-6" data-aos="zoom-in" data-aos-delay="400">
                <div className="box">
                  <h3 style={{ color: "#07d5c0" }}>{dataObj.package_name}</h3>
                  <div className="price"><sup>₹</sup>{dataObj.price}<span> / mo</span></div>
                  <img src={apiUrl() + '/assest/pricing/' + dataObj.image} className="img-fluid" alt="" />

                  <div className=" mt-2 mb-3">

                    <div id="pricing-content-4">
                      <div >
                        <table style={{ width: "100%" }}>
                          <tbody style={{ fontSize: "14px" }}>
                            <tr>
                              <td>Number of Templates</td>
                              <td>{dataObj.num_of_template}</td>
                            </tr>
                            <tr>
                              <td>Cover Letter</td>
                              <td>{dataObj.cover_letter}</td>
                            </tr>
                            <tr>
                              <td>Normal AI Edits Per Year</td>
                              <td>{dataObj.credit_points}</td>
                            </tr>
                            <tr>
                              <td>Premium 10X Powerful AI Engine</td>
                              <td>{dataObj.premium_powerful_AI}</td>
                            </tr>
                            <tr>
                              <td>MagicPoint Water Mark</td>
                              <td>{dataObj.water_mark}</td>
                            </tr>
                            <tr>
                              <td>Download Option</td>
                              <td>{dataObj.download_option}</td>
                            </tr>
                            <tr>
                              <td>Premium Templates</td>
                              <td>{dataObj.premium_template}</td>
                            </tr>
                            <tr>
                              <td>Email Support</td>
                              <td>{dataObj.email_support}</td>
                            </tr>
                            <tr>
                              <td>Phone Support</td>
                              <td>{dataObj.phone_support}</td>
                            </tr>
                            <tr>
                              <td>Portfolio</td>
                              <td>{dataObj.portfolio}</td>
                            </tr>
                            <tr>
                              <td>Custom Domain Portfolio</td>
                              <td>{dataObj.custom_domain}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  {/* <Link to={`/subscription?packageId=${dataObj.package_list_id}`} className="btn-buy ">Buy Now</Link> */}
                  <Link to={`/checkout?packageId=${dataObj.package_list_id}`} className="btn-buy ">Buy Now</Link>
                </div>
              </div>
            ))
          ) : (
            <p></p>
          )}
        </div>
      </div>
    </section>
  )
}
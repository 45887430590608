import "../css/login.css";
import React, { useState, useEffect } from 'react';
import jwtDecode from 'jwt-decode';
import axios from "axios";
import Cookies from 'js-cookie';
import { apiUrl } from "../function";
import { useNavigate, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';


export default function Forgetpassword() {
  const navigate = useNavigate();
  useEffect(() => {
    // if (Cookies.get('userAuth') != undefined) {
    //   navigate(`/`, { replace: true });
    //   return;
    // }
  }, [])

  const [inputValues, setInputValues] = useState({
    email: '',
  });

  const [msg, setMsg] = useState('');

  const handleInputChange = (event) => {
    setMsg('');
    const { name, value } = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    setMsg('');
    e.preventDefault();

    sendEmailVerification(inputValues.email);

  };

  const sendEmailVerification = async (inputEmail) => {
    try {
      const response = await axios.post(apiUrl() + '/send-validation-email', {
        inputEmail
      })
      if (response.data.statusCode == "200") {
        alert('Change Password link has been sent to given email id');
        window.location.reload()
      }
      else {
        setMsg(response.status)
      }

    } catch (error) {
      setMsg(error.response.data.message);
      console.error('--error--', error);
    }
  }

  return (

    <div>
      <div className="login">

        <div className="login-container">
          <h1>Forget Password</h1>
          <form onSubmit={handleSubmit}>
            <TextField sx={{ mb: 1, width: '25ch', }} id="standard-basic" label="Enter Email" name="email" variant="standard" value={inputValues.email}
              onChange={handleInputChange} /><br />
            <span className="text-danger">{msg}</span><br />
            <Button type="submit" variant="contained" style={{ padding: "10px", width: "25ch" }}>Forget</Button>
          </form>
        </div>

      </div>

    </div>




  );
};
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from "../function";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
import Sidebarmenue from "../components/sidebar"
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import '../css/userCoverLetter.css'


export default function UserCoverLetter() {

    const [coverPageData, setCoverPageData] = useState([]);
    const [content, setContent] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [companyName, setCompanyName] = useState('');

    useEffect(() => {
        getuserCoverLetter();
    }, [])

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const getuserCoverLetter = async () => {
        let tmpArray = [];

        try {
            let authToken = Cookies.get('userAuth');
            let headers = {
                Authorization: `Bearer ${authToken}`,
            };
            let response = await axios.get(apiUrl() + '/get-user-cover-letter', { headers });
            if (response.data.statusCode == 200) {
                console.log('----response.data.userData---', response.data.userData);
                setCoverPageData(...coverPageData, response.data.userData);
            }
        } catch (error) {
            console.error(error);
        }
    }
    const handleProcedureContentChange = (value) => {
        setCoverPageData(value);
    };

    const handleChanges = (event) => {
        setCompanyName(event.target.value);
    };


    const handleSaveClick = async () => {
        setIsEditing(false);
        let token = Cookies.get('userAuth')

        const formData = {
            coverPageData: coverPageData,
        };

        try {
            const response = await axios.post(apiUrl() + '/update-user-cover-page',
                formData,
                { headers: { 'Authorization': `Bearer ${token}` } })
            if (response.data.statusCode == "200") {
                window.location.reload();
            } else {
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div className=' usrcvr'>
            <Sidebarmenue />
            <table className="table-responsive-sm table  table-bordered text-center ">

                {!isEditing ? (<thead>
                    <tr>
                        <th className="table-header">Your Cover Letters</th>
                        <th className="table-header">Your Company</th>
                        <th className="table-header">Action</th>

                    </tr>
                </thead>) : (<></>)}

                <tbody>
                    {coverPageData ? (coverPageData.map((dataObj) => (
                        <tr style={{ maxWidth: '50%!important', overflow: 'auto', textWrap: 'balance', margin: 'auto' }}>
                            <td style={{ paddingLeft: '20px', width: '50%' }} ><p dangerouslySetInnerHTML={{ __html: dataObj.cover_page }} /></td>
                            <td className="text-center align-middle">{dataObj.company_name}</td>
                            <td className="text-center align-middle">

                                <Link to={`/updateUserCoverLetter?coverPageId=${dataObj.cover_page_id}`} className="edtr_btn">Edit</Link>
                            </td>
                        </tr>
                    ))) : <></>}

                </tbody>
            </table>
        </div>


    )
}
import React from 'react';
import Typewriter from "typewriter-effect";
import { Link } from 'react-router-dom';
import '../css/GetStarted.css'; 

const GetStarted=()=>{
    return <div className='log'>
        
        <div className="left">
   
        
        <Typewriter 
 
 onInit={(typewriter) => {
     typewriter
         .typeString("Welcome!!")
         .pauseFor(2100)
         .deleteAll()
         .typeString("Create your Resume in just 5 minutes")
         .pauseFor(1900)
         .deleteAll()
         .typeString("You need to login or Signup first")
         .start();
 }}
/>
    </div>
    <div className="right">
        <div className="container">
            <h1>Get Started</h1>
            <Link to="/login" className="button">Login</Link>
           <Link to="/" className="button">Sign up</Link>
        </div>
        <div className="bottoms">
            
            <button className="links">Terms and Conditions</button>
            <button className="links">Privacy policy</button>
        </div>
    </div>
    </div>
}


export default GetStarted;
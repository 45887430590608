
import Hero from "../components/hero";
import Countcard from "../components/countCard";
import Features from "../components/features";
import Pricing from "../components/pricing";
import Faq from "../components/faq";
import Testimoinal from "../components/testimonials";
import Contact from "../components/contactus";
import Benefits from "../components/form/benefits";
import TermsandConditons from "../components/form/TAC";
export default function Home() {


    return (
        < >
            <Hero />
            {/* <Countcard/> */}
            {/* <Features/> */}
            <Benefits />
            <Pricing />
            <Testimoinal />
            <Faq />


        </>

    );
}
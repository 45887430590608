import Sidebarmenue from "../components/sidebar"
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from "../function";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";
var base64 = require('base-64');
export default function Currentplan() {
    const [packageList, setpackageList] = useState('')
    const getcurrentPlan = async () => {
        try {
            let authToken = Cookies.get('userAuth');
            let headers = {
                Authorization: `Bearer ${authToken}`,
            };
            let response = await axios.get(apiUrl() + '/get-current-plan', { headers });
            if (response.data.statusCode == 200) {

                let list = response.data.package.map((item, key) => {
                    return <>
                        <td>{item.package_name}</td>
                        <td>{item.price}</td>
                        <td>{item.num_of_template}</td>
                        <td>{item.cover_letter}</td>
                        <td>{item.credit_points}</td>
                        <td>{item.premium_powerful_AI}</td>
                        <td>{item.water_mark}</td>
                        <td>{item.premium_template}</td>
                        <td>{item.download_option}</td>
                        <td>{item.email_support}</td>
                        <td>{item.phone_support}</td>
                        <td>{item.portfolio}</td>
                        <td>{item.custom_domain}</td>
                        <td style={{ backgroundColor: response.data.expairedDate ? 'green' : 'red', fontWeight: 'bold' }}>{response.data.expairedDate ? "Active" : "Expired"}</td>
                    </>
                })
                setpackageList(list)
            }

        } catch (error) {
            console.error(error);
        }

    };

    useEffect(() => {
        getcurrentPlan()
    }, [])
    return (
        <>
            <div style={{ display: 'flex', height: '100%', minHeight: '400px', marginTop: "100px" }}>
                <Sidebarmenue />

                <div style={{ width: "80%", height: "100vh", backgroundColor: "white", boxShadow: "0 3px 7px rgba(0, 0, 0, 0.2)", display: "flex", flexDirection: "column", alignItems: "center" }}>
                    <h2 style={{ fontSize: "25px", fontFamily: "Verdana", margin: "20px", fontWeight: "bolder", color: "#0D3779" }}>Current Plan</h2>
                    <div className="container table-responsive" style={{ width: '100%' }}>
                        <table className="table table-hover align-middle">
                            <thead className="table-dark">
                                <tr>
                                    <th scope="col">Package</th>
                                    <th>Price</th>
                                    <th>Number of template</th>
                                    <th>Cover letters</th>
                                    <th>Credit point</th>
                                    <th>Premium powerful AI</th>
                                    <th>Water mark</th>
                                    <th>Premium template</th>
                                    <th>Download option</th>
                                    <th>Email support</th>
                                    <th>Phone support</th>
                                    <th>Portfolio</th>
                                    <th>Custom domain</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {packageList}
                                </tr>
                            </tbody>

                        </table>

                    </div>
                    <Link to="/pricing" class="btn-btn-blue">CHANGE PLAN</Link>
                </div>
            </div>

        </>
    )
}
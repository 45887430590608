import ReactDOMServer from "react-dom/server";
import Loadtemplate from "./loadTemplate";
import { useState, useEffect } from "react";
import { apiUrl, dataURLtoFile } from "../function";


import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Document, Page } from '@react-pdf/renderer';
import { pdfjs } from 'react-pdf';

import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import htmlToPdfmake from 'html-to-pdfmake';
import html2pdf from 'html2pdf.js';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


export default function Resumeview({ resume, personalInfoData, workExpData,
    eduData, projectData, skillSet, socialLinks, languages, hobbies, certifications,
    techskill, OtherPersonalDetails, ExtracurricularActivities, CustomSection }) {
    let [scale, setScale] = useState(0.5)
    let [width, setWidth] = useState(408)
    let [height, setHegiht] = useState(1000)


    // const srcDoc = <Loadtemplate resume={resume} value={values} />;
    const srcDoc = <Loadtemplate
        resume={resume}
        personalInfoData={personalInfoData}
        workExpData={workExpData}
        eduData={eduData}
        projectData={projectData}
        skillSet={skillSet}
        socialLinks={socialLinks}
        languages={languages}
        hobbies={hobbies}
        certifications={certifications}
        techskill={techskill}
        OtherPersonalDetails={OtherPersonalDetails}
        ExtracurricularActivities={ExtracurricularActivities}
        CustomSection={CustomSection} />


    function zoomIn() {
        width = width + 90
        setWidth(width)
        height = height + 10
        setHegiht(height)
        scale = scale + 0.1
        setScale(scale)
    };

    function zoomOut() {
        width = width - 50
        setWidth(width)
        height = height - 10
        setHegiht(height)
        scale = scale - 0.1
        setScale(scale)
    };

    const iframeRef = useRef(null);
    const [pdfData, setPdfData] = useState('');


    // function convertToPDF() {
    //     var doc = new jsPDF('p', 'pt', 'a4');
    //     let pdfjs = document.querySelector('#divID');

    //     // var width = doc.internal.pageSize.getWidth();
    //     var width = 700;
    //     var height = doc.internal.pageSize.getHeight();

    //     // Set margins and padding
    //     const margin = 10; // in mm
    //     const padding = 10; // in mm
    //     const contentWidth = 210 - 2 * margin; // A4 width minus left and right margin
    //     const contentHeight = 297 - 2 * margin; // A4 height minus top and bottom margin
    //     // Add a page with the defined margins
    //     doc.addPage();
    //     doc.setFont('helvetica');
    //     doc.setFontSize(2);
    //     doc.scale(4.9);

    //     doc.html(pdfjs, {
    //         margin: [0, 0, 0, 0], width, height,
    //         callback: function (doc) {
    //             doc.save("newpdf.pdf");
    //         },
    //         html2canvas: { scale: 1.6 }
    //     });
    // }

    // function convertToPDF() {
    //     const doc = new jsPDF();
    //     const pdfTable = document.querySelector('#divID');
    //     var html = htmlToPdfmake(pdfTable.innerHTML);
    //     const documentDefinition = { content: html };
    //     pdfMake.vfs = pdfFonts.pdfMake.vfs;
    //     pdfMake.createPdf(documentDefinition).open();
    // }

    const convertToPDF = () => {
        // const element = document.querySelector('iframe').contentWindow.document.querySelector('#divID')
        const element = document.querySelector('#divID');
        html2pdf(element, {
            filename: 'download.pdf',
            html2canvas: { scale: 4 },
            jsPDF: {
                unit: 'mm',
                format: 'a4',
                orientation: 'portrait',
                compressPDF: true,
                putOnlyUsedFonts: true,
                precision: 16,
                userUnit: 2.0
            }
        });
    };


    // const convertToPDF = () => {
    //     console.log('----convertToPDF---');
    //     const content = document.getElementById('divID');
    //     const pdfOptions = {
    //         margin: 10,
    //         filename: 'resume.pdf',
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 2 },
    //         jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    //     };
    //     html2pdf().from(content).set(pdfOptions).outputPdf(pdf => {
    //         console.log('----convertToPDF--html2pdf().from----');
    //         const blob = new Blob([pdf], { type: 'application/pdf' });
    //         html2pdf().save(blob, { filename: pdfOptions.filename });
    //     });
    // }

    return (
        <div className="col-span-3">
            <div className="relative flex justify-center md:justify-start">
                <div className="invisible shrink-[10000] grow hidden md:block" style={{ maxWidth: "50px", minWidth: "0px" }}>
                </div>
                <div className="relative">
                    <section className="h-[calc(100vh-var(--top-nav-bar-height)-var(--resume-control-bar-height))] overflow-hidden md:p-[var(--resume-padding)]">
                        <div style={{ maxWidth: width, maxHeight: "696.96px" }}>
                            <div className="origin-top-left" id="divID"
                                style={{ width: "830px", height: "1056px", transform: `scale(${scale})`, overflow: "auto" }}>

                                <Loadtemplate
                                    resume={resume}
                                    personalInfoData={personalInfoData}
                                    workExpData={workExpData}
                                    eduData={eduData}
                                    projectData={projectData}
                                    skillSet={skillSet}
                                    socialLinks={socialLinks}
                                    languages={languages}
                                    hobbies={hobbies}
                                    certifications={certifications}
                                    techskill={techskill}
                                    OtherPersonalDetails={OtherPersonalDetails}
                                    ExtracurricularActivities={ExtracurricularActivities}
                                    CustomSection={CustomSection} />

                            </div>
                        </div>
                    </section>
                </div>

            </div>
            <div className="resume-build-footer absolute bottom-[var(--resume-control-bar)]  border-t-2 bg-gray-50">

                <div className="sticky bottom-0 left-0 right-0 flex h-[var(--resume-control-bar-height)] items-center justify-center px-[var(--resume-padding)] text-gray-600 lg:justify-between">
                    <button className="zoominOut" onClick={zoomIn}>+</button>
                    <button className="zoominOut" onClick={zoomOut}>-</button>

                    {/* <a className="ml-1 flex items-center gap-1 rounded-md border border-gray-300 px-3 py-0.5 hover:bg-gray-100 lg:ml-8"
                        href=""><svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-4 w-4">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3">
                            </path>
                        </svg>


                    </a> */}
                    {/* <button className="ml-1 flex items-center gap-1 rounded-md border border-gray-300 px-3 py-0.5 hover:bg-gray-100 lg:ml-8" onClick={convertToPDF}>Convert to PDF</button> */}
                    <button onClick={convertToPDF}>Convert to PDF</button>
                </div>
            </div>
        </div>
    )
}
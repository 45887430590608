import { useState } from "react"
export default function Template4({ personalInfoData, workExpData, eduData, projectData,
  skillSet, socialLinks, languages, hobbies, certifications, techskill, OtherPersonalDetails,
  ExtracurricularActivities, CustomSection }) {
  let expData = workExpData.data;
  let educationData = eduData.data;
  let personalData = personalInfoData.inputValue;
  let projectDetails = projectData.data;
  let skillSetData = skillSet.data;
  let socialLinksData = socialLinks.data;
  let languagesData = languages.data;
  let hobbiesData = hobbies.data;
  let certification = certifications.data
  let techSkill = techskill.data
  let OPD = OtherPersonalDetails.data
  let ECA = ExtracurricularActivities.data
  let CS = CustomSection.data

  return (

    <div style={{ color: 'black', backgroundColor: '#7FFFD4', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)', borderTop: '5px solid blue', borderBottom: '5px solid green' }}>

      {personalData ? (

        <div>
          <div style={{ display: 'flex' }}>
            <img style={{ height: '150px' }} src={personalData.profile_pic} />
            <div style={{ textAlign: 'center', marginBottom: '20px', marginLeft: '20px' }}>
              <div style={{ fontSize: '36px', fontWeight: 'bold', color: '#333' }}>{personalData.name}</div>
              <div style={{ fontSize: '18px', color: '#777', marginTop: '10px' }}>{personalData.email ? "Email:" : ""} {personalData.email} {personalData.phone ? "| Phone:" : ''}  {personalData.phone}<br />{personalData.workExperienceyear ? "Work Experience:" : ''}  {personalData.workExperienceyear} / {personalData.workExperiencemonth} </div>

              <div style={{ marginBottom: '20px', border: '1px dotted #ccc', padding: '15px', borderRadius: '8px', overflowWrap: 'break-word', }}>
                <div style={{ fontSize: '20px', fontWeight: 'bold', color: '#555', marginBottom: '5px' }}></div>
                <div style={{ fontSize: '18px', color: '#777', }}>{personalData.resumeHeadline}</div>
              </div>
            </div>
          </div>

          <div style={{ marginTop: '30px' }}>
            <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginBottom: '15px' }}>{personalData.summary ? "Summary" : ''}</div>
            <div style={{ marginBottom: '20px', border: '1px dotted #ccc', padding: '15px', borderRadius: '8px', overflowWrap: 'break-word', }}>
              <div style={{ fontSize: '20px', fontWeight: 'bold', color: '#555', marginBottom: '5px' }}></div>
              <div style={{ fontSize: '18px', color: '#777', }}>{personalData.summary}</div>
            </div>
          </div>
        </div>
      ) : <p></p>}


      {educationData ? (
        educationData.map((value, index) => (
          <div >

            {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> EDUCATION</div>) : ('')}
            <div>
              <div style={{ marginBottom: '20px', border: '1px dotted #ccc', padding: '15px', borderRadius: '8px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}><div style={{ fontSize: '20px', fontWeight: 'bold', color: '#555', marginBottom: '5px' }}>{value.courseName} - {value.specialization}</div>
                  <div style={{ fontSize: '18px', color: '#777' }}>{value.markes} {value.gradingSystem}</div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}><div style={{ fontSize: '18px', fontWeight: 'bold', color: 'black', marginBottom: '5px' }}>{value.school} - {value.courseType}</div>
                  <div style={{ fontSize: '18px', color: 'black' }}>{value.date}</div></div>
                <p>{value.descp}</p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p></p>
      )}

      {/* <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}>WORK EXPERIENCE</div> */}
      {expData ? (
        expData.map((item, index) => (

          <div>
            {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> WORK EXPERIENCE</div>) : ('')}
            <div style={{ marginBottom: '20px', border: '1px dotted #ccc', padding: '15px', borderRadius: '8px' }}>
              <div style={{ fontSize: '20px', fontWeight: 'bold', color: '#555', marginBottom: '5px' }}>{item.jobTitle} - {item.company}</div> <span style={{ fontSize: '14px' }}>{item.workSinceYear} - {item.worksincemonth} To {item.workSincetoYear} - {item.worksincetomonth}</span>
              <div style={{ fontSize: '18px', color: '#777' }}>{item.descp}</div>
            </div>
          </div>
        ))
      ) : (
        <p></p>
      )}


      {projectDetails ? (
        projectDetails.map((item, index) => (
          <div style={{ marginTop: '30px' }}>
            {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> Project Details</div>) : ('')}

            <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginBottom: '15px' }}>{item.heading}</div>
            <div style={{ marginBottom: '20px', border: '1px dotted #ccc', padding: '15px', borderRadius: '8px', display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '20px', fontWeight: 'bold', color: '#555', marginBottom: '5px' }}>{item.projectName} - {item.projectRole}</div>
              <div style={{ fontSize: '18px', color: '#777' }}>{item.projectDesc}</div>
              <span style={{ fontSize: '12px', fontWeight: 'normal', marginLeft: 'auto' }}>at {item.clientCollege} in year {item.year} / Guided by {item.ManagerGuide}</span>
            </div>
          </div>
        ))
      ) : (
        <p></p>
      )}

      {/* <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}>CERTIFICATIONS</div> */}
      {certification ? (
        certification.map((item, index) => (
          <div>
            {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> Certification</div>) : ('')}

            <div style={{ marginBottom: '20px', border: '1px dotted #ccc', padding: '15px', borderRadius: '8px', display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '20px', fontWeight: 'bold', color: '#555', marginBottom: '5px' }}>{item.certificationCourse}   (Id -{item.certificateID})</div>
              <span style={{ fontSize: '14px', fontWeight: 'normal' }}>Issued on:{item.issueDateMonth} {item.issueDateYear} | Expires on:{item.expiryDateMonth} {item.expiryDateYear}</span>
              <p>{item.CertificateVerificationURL}</p>
            </div>
          </div>
        ))
      ) : (
        <p></p>
      )}

      {/* <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}>EXTRA CURRICULAR ACTIVITIES</div> */}
      {ECA ? (
        ECA.map((value, index) => (
          <div>
            {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> EXTRA CURRICULAR ACTIVITIES</div>) : ('')}
            <div style={{ marginBottom: '20px', border: '1px dotted #ccc', padding: '15px', borderRadius: '8px', display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '20px', fontWeight: 'bold', color: '#555', marginBottom: '5px' }}>{value.activityName}</div>
              <p>{value.descp}</p>
            </div>
          </div>
        ))
      ) : (
        <p></p>
      )}


      {CS ? (
        CS.map((value, index) => (
          <div style={{ marginTop: '30px' }}>

            {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> CERTIFICATIONS</div>) : ('')}

            <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginBottom: '15px' }}>{value.heading}</div>
            <div style={{ marginBottom: '20px', border: '1px dotted #ccc', padding: '15px', borderRadius: '8px', display: 'flex', flexDirection: 'column' }}>
              <div style={{ fontSize: '20px', fontWeight: 'bold', color: '#555', marginBottom: '5px' }}>{value.activityName}</div>
              <p>{value.descp}</p>
            </div>
          </div>
        ))
      ) : (
        <p></p>
      )}

      {/* <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}>SKILLS</div> */}
      <div style={{ display: 'flex' }}>
        {skillSetData ? (
          skillSetData.map((item, index) => (
            <div>
              {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> SKILLS</div>) : ('')}

              <ul style={{ listStyle: 'none', padding: '0', display: 'flex', flexWrap: 'wrap' }}>
                <li style={{ backgroundColor: '#f0f0f0', color: '#333', padding: '8px 15px', marginRight: '10px', marginBottom: '10px', borderRadius: '5px' }}>{item.skillName}</li>
              </ul>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>

      {/* <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}>TECHNICAL SKILLS</div> */}
      <div style={{ display: 'flex' }}>
        {techSkill ? (
          techSkill.map((item, index) => (
            <div>
              {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> TECHNICAL SKILLS</div>) : ('')}

              <ul style={{ listStyle: 'none', padding: '0', display: 'flex', flexWrap: 'wrap' }}>
                <li style={{ backgroundColor: '#f0f0f0', color: '#333', padding: '8px 15px', marginRight: '10px', marginBottom: '10px', borderRadius: '5px' }}>{item.skillName}</li>
              </ul>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>

      {/* <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}>LANGUAGES</div> */}
      <div >
        {languagesData ? (
          languagesData.map((item, index) => (
            <div>

              {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> TECHNICAL SKILLS</div>) : ('')}

              <ul style={{ listStyle: 'none', padding: '0', display: 'flex', flexWrap: 'wrap' }}>
                <li style={{ backgroundColor: '#f0f0f0', color: '#333', padding: '8px 15px', marginRight: '10px', marginBottom: '10px', borderRadius: '5px' }}>{item.language}</li>
              </ul>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>

      {/* <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}>HOBBIES</div> */}
      <div style={{ display: 'flex' }}>
        {hobbiesData ? (
          hobbiesData.map((item, index) => (
            <div>
              {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}>HOBBIES</div>) : ('')}

              <ul style={{ listStyle: 'none', padding: '0', display: 'flex', flexWrap: 'wrap' }}>
                <li style={{ backgroundColor: '#f0f0f0', color: '#333', padding: '8px 15px', marginRight: '10px', marginBottom: '10px', borderRadius: '5px' }}>{item.hobbies}</li>
              </ul>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>

      {/* <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}>SOCIAL LINKS</div> */}
      <div style={{ display: 'flex' }}>
        {socialLinksData ? (
          socialLinksData.map((item, index) => (
            <div>

              {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> SOCIAL LINKS</div>) : ('')}

              <ul style={{ listStyle: 'none', padding: '0', display: 'flex', flexWrap: 'wrap' }}>
                <li style={{ backgroundColor: '#f0f0f0', color: '#333', padding: '8px 15px', marginRight: '10px', marginBottom: '10px', borderRadius: '5px' }}>{item.socialLinks}</li>
              </ul>
            </div>
          ))
        ) : (
          <p></p>
        )}
      </div>

      {/* <div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}>EXTRA CURRICULAR ACTIVITIES</div> */}
      {OPD ? (
        OPD.map((value, index) => (<div style={{ width: '100%', marginLeft: '20px' }}>
          {(index == "0") ? (<div style={{ fontSize: '24px', fontWeight: 'bold', color: '#333', marginTop: '30px', marginBottom: '15px' }}> OTHER PERSONAL DETAILS</div>) : ('')}

          <table style={{ width: '60%', fontWeight: 'bold' }}>
            <tr>
              <td>Marritial Status :</td>
              <td>{value.MaritalStatus}</td>
            </tr>
            <tr>
              <td>Nationality :</td>
              <td>{value.Nationality}</td>
            </tr>
            <tr>
              <td>address :</td>
              <td>{value.address}</td>
            </tr>
            <tr>
              <td>City :</td>
              <td>{value.city}</td>
            </tr>
            <tr>
              <td>Country :</td>
              <td>{value.country}</td>
            </tr>
            <tr>
              <td>Pin Code :</td>
              <td>{value.pincode}</td>
            </tr>
            <tr>
              <td>D.O.B. :</td>
              <td>{value.dob}</td>
            </tr>
            <tr>
              <td>Gender :</td>
              <td>{value.gender}</td>
            </tr>
          </table>
        </div>
        ))
      ) : (
        <p></p>
      )}

      {/* {languagesData ? (
        languagesData.map((item) => (
          <li >
            <p>heading: {item.heading}</p>
            <p>language: {item.language}</p>

          </li>
        ))
      ) : (
        <p></p>
      )} */}

    </div>
  );

}
import "./css/footer.css";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <footer id="footer" className="footer">

      <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <Link href="index.html" className="logo d-flex align-items-center">
                <img src="assets/img/logo/thejobsearch_logo.png" alt="" />
              </Link>
              <p></p>
              <div className="social-links mt-3">
                <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
              </div>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><i className="bi bi-chevron-right"></i> <Link to="/">Home</Link></li>
                <li><i className="bi bi-chevron-right"></i>  <Link to="/about">About</Link></li>
                <li><i className="bi bi-chevron-right"></i>  <Link to="/contactus">Contact</Link></li>
                <li><i className="bi bi-chevron-right"></i> <Link to="/termsandconditions">Terms & Conditions</Link></li>
                <li><i className="bi bi-chevron-right"></i> <Link to="/privacypolicy">Privacy Policy</Link></li>
              </ul>
            </div>

            <div className="col-lg-2 col-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li><i className="bi bi-chevron-right"></i> <Link to="/pricing">Pricing</Link></li>
                <li><i className="bi bi-chevron-right"></i>  <Link to="/register">Register</Link></li>
                <li><i className="bi bi-chevron-right"></i>  <Link to="/login">Login</Link></li>

              </ul>
            </div>


            <div className="col-lg-3 col-md-12 footer-contact text-center text-md-start">
              <h4>Contact Us</h4>
              <p>
               The Job Search - the AI resume builder.
                <br />
                <strong>Phone:</strong> +91 94242-82486
                <br />
                <strong>Email:</strong> info@thejobsearch.in
              </p>

            </div>

          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong><span>The Job Search</span></strong>. All Rights Reserved
        </div>
        <div className="credits">

          Designed by <a href="http://lakshyasoftech.in/"> Lakshya Softech</a>
        </div>
      </div>
    </footer>
  )
}
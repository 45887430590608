import React, { useState, useEffect } from 'react';
import "../css/Certifications.css";
import Validate from '../../validation';
import axios from "axios";
import Cookies from 'js-cookie';
import { apiUrl, jwtVerify, monthswithNum } from '../../function';
const vld = new Validate
function Certifications({ onCertificationChange }) {
    const [formValues, setFormValues] = useState([{ heading: 'Certifications', certificationCourse: '', certificateID: '', CertificateVerificationURL: '', issueDateMonth: '', issueDateYear: '', expiryDateMonth: '', expiryDateYear: '', errorMsg: '' }]);
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [validate, setValidate] = useState(false)
    let handleChange = (i, e) => {
        let newFormValues = [...formValues];
        if (e.target.name == "expiryDateYear" || e.target.name == "issueDateYear" || e.target.name == "issueDateMonth" || e.target.name == "expiryDateMonth") {
            if (e.target.name == "issueDateYear" && e.target.value > formValues[i].expiryDateYear) {
                formValues[i].errorMsg = "Issue Year must be less then expiry year!"
                setValidate(false)
            }
            else if (e.target.name == "expiryDateYear" && formValues[i].issueDateYear > e.target.value) {
                formValues[i].errorMsg = "Expiry year must be greater then issue year!"
                setValidate(false)
            }
            else if (e.target.name == "expiryDateMonth" && formValues[i].issueDateYear == formValues[i].expiryDateYear && monthswithNum(formValues[i].issueDateMonth) >= monthswithNum(e.target.value)) {
                formValues[i].errorMsg = "Expiry month must be greater then issue month!"
                setValidate(false)
            }
            else if (e.target.name == "issueDateMonth" && formValues[i].issueDateYear == formValues[i].expiryDateYear && monthswithNum(formValues[i].expiryDateMonth) <= monthswithNum(e.target.value)) {
                formValues[i].errorMsg = "Issue month must be less then expiry month!"
                setValidate(false)
            }
            else {
                formValues[i].errorMsg = " "
                setValidate(true)
            }
        }
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }
    let addFormFields = () => {
        setFormValues([...formValues, { heading: 'Certifications', certificationCourse: '', certificateID: '', CertificateVerificationURL: '', issueDateMonth: '', issueDateYear: '', expiryDateMonth: '', expiryDateYear: '', }])
    }
    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
        onCertificationChange(newFormValues); // Notify parent about the change
    }
    let handleSubmit = async (event) => {
        event.preventDefault();
        if (!validate) {
            return
        }
        onCertificationChange(formValues); // Notify parent about the change
        let token = Cookies.get('userAuth')
        try {
            const response = await axios.post(apiUrl() + '/submit-resume',
                { Certification: formValues },
                { headers: { 'Authorization': `Bearer ${token}` } })
            if (response.data.statusCode == "200") {

            }
            else {

            }
        } catch (error) {

        }
    }
    async function getYM() {
        try {
            let response = await axios.get(apiUrl() + '/get-YM');
            let yearOption = response.data.Iyear.map((item, index) => {
                return <option key={index}>{item.year}</option>
            })
            setYear(yearOption)
            let monthOption = response.data.Smonth.map((item, index) => {
                return <option key={index}>{item.month}</option>
            })
            setMonth(monthOption)
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        getYM()
        let x = localStorage.getItem("CRTF" + jwtVerify("userAuth").userId);
        if (x != null) {
            x = JSON.parse(x);
            setFormValues(x)
            onCertificationChange(x);
        }
        else {
            let usertoken = Cookies.get('userAuth')
            getUserCertification(usertoken);
        }
    }, [])
    const getUserCertification = (usertoken) => {
        return axios.get(apiUrl() + `/get-users-certification-detail`, { headers: { 'Authorization': `Bearer ${usertoken}` } })
            .then(response => {
                if (response.data.statusCode == "200") {
                    setFormValues(response.data.userCertificationData);
                } else {

                }
            })
            .catch(error => {
                console.error(error);
            });
    };
    return (
        <>
            <form onSubmit={handleSubmit} className="">
                {formValues.map((element, index) => (
                    <div className="form-inline" key={index}>
                        <section style={{ marginBottom: '10px' }} className="flex flex-col gap-3 rounded-md bg-white p-6 pt-4 shadow transition-opacity duration-200 transition-opacity duration-200 pb-6">
                            <div className="flex items-center justify-between gap-4">
                                <div className="flex grow items-center gap-2">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                        aria-hidden="true" className="h-6 w-6 text-gray-600">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21">
                                        </path>
                                    </svg>
                                    <input
                                        className="block w-full border-b border-transparent text-lg font-semibold tracking-wide text-gray-900 outline-none hover:border-gray-300 hover:shadow-sm focus:border-gray-300 focus:shadow-sm"
                                        type="text"
                                        name="heading" value={element.heading || 'CERTIFICATION'} onChange={e => handleChange(index, e)}
                                    />
                                </div>
                            </div>
                            <div className="grid overflow-hidden transition-all duration-300 visible"
                                style={{ gridTemplateRows: "1fr" }}>
                                <div className="min-h-0">
                                    <div className="relative grid grid-cols-6 gap-3">
                                        <label className="text-base font-medium text-gray-700 col-span-full">Certification Course
                                            <input className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                type="text"
                                                placeholder="Certification Course"
                                                name="certificationCourse" value={element.certificationCourse || ""}
                                                onChange={e => handleChange(index, e)}
                                            />
                                        </label>
                                        <label className="text-base font-medium text-gray-700 col-span-full">Certificate ID
                                            <input className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                type="text"
                                                placeholder="Certificate ID"
                                                name="certificateID" value={element.certificateID || ""}
                                                onChange={e => handleChange(index, e)}
                                            />
                                        </label>
                                        <label className="text-base font-medium text-gray-700 col-span-full">Certificate Verification URL
                                            <input className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                type="text"
                                                placeholder="Certificate Verification URL"
                                                name="CertificateVerificationURL" value={element.CertificateVerificationURL || ""}
                                                onChange={e => handleChange(index, e)}
                                            />
                                        </label>
                                        <label className="text-base font-medium text-gray-700 col-span-3">
                                            Issue Date
                                            <select className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                name="issueDateMonth" value={element.issueDateMonth || ""}
                                                onChange={e => handleChange(index, e)}
                                            >
                                                <option>MM</option>
                                                {month}
                                            </select>
                                        </label>
                                        <label className="text-base font-medium text-gray-700 col-span-3">
                                            &nbsp;
                                            <select className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                name="issueDateYear" value={element.issueDateYear || ""}
                                                onChange={e => handleChange(index, e)}
                                            >
                                                <option>YYYY</option>
                                                {year}
                                            </select>
                                        </label>
                                        <label className="text-base font-medium text-gray-700 col-span-3">
                                            Expiry Date
                                            <select className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                type="text"
                                                placeholder="Expiry Date"
                                                name="expiryDateMonth" value={element.expiryDateMonth || ""}
                                                onChange={e => handleChange(index, e)}
                                            >
                                                <option>MM</option>
                                                {month}
                                            </select>
                                        </label>
                                        <label className="text-base font-medium text-gray-700 col-span-3">
                                            &nbsp;
                                            <select className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                type="text"
                                                placeholder="Expiry Date"
                                                name="expiryDateYear" value={element.expiryDateYear || ""}
                                                onChange={e => handleChange(index, e)}
                                            >
                                                <option>YYYY</option>
                                                {year}
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <span className='text-danger text-center mt-2'>{element.errorMsg}</span>
                            </div>
                            <div className="mt-2 flex justify-end">
                            </div>
                        </section>
                        <div className="mt-2 flex justify-end">
                            {
                                index ?
                                    <button type="button" onClick={() => removeFormFields(index)}><i class="bi bi-trash3-fill"></i> </button>
                                    : null
                            }
                        </div>
                    </div>
                ))}
                <div className="mt-2 flex justify-end">
                    <button
                        type="submit"
                        // onClick={handleSave}
                        className="btn-space flex items-center rounded-md bg-white py-2 pl-3 pr-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                            className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"></path>
                        </svg>
                        Save
                    </button>
                    <button type="button"
                        //onClick={() => handleAddField(fieldsetIndex)}
                        onClick={() => addFormFields()}
                        className="flex items-center rounded-md bg-white py-2 pl-3 pr-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        <svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                            className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400 ">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"></path>
                        </svg>
                        Add Job</button>
                </div>
            </form>
        </>
    )
}
export default Certifications;
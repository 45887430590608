import Template1 from "./templates/template1"
import Template2 from "./templates/template2";
import Template3 from "./templates/template3";
import Template4 from "./templates/template4";
import Template5 from "./templates/template5";
import Template6 from "./templates/template6";
export default function Loadtemplate({ resume, personalInfoData, workExpData, eduData,
    projectData, skillSet, socialLinks, languages, hobbies, certifications, techskill,
    OtherPersonalDetails, ExtracurricularActivities, CustomSection }) {


    const resumeDesign = resume;

    switch (resumeDesign) {
        case "1":
            return <Template1
                resume={resume}
                personalInfoData={personalInfoData}
                workExpData={workExpData}
                eduData={eduData}
                projectData={projectData}
                skillSet={skillSet}
                socialLinks={socialLinks}
                languages={languages}
                hobbies={hobbies}
                certifications={certifications}
                techskill={techskill}
                OtherPersonalDetails={OtherPersonalDetails}
                ExtracurricularActivities={ExtracurricularActivities}
                CustomSection={CustomSection}

            />

        case "2":
            return <Template2
                resume={resume}
                personalInfoData={personalInfoData}
                workExpData={workExpData}
                eduData={eduData}
                projectData={projectData}
                skillSet={skillSet}
                socialLinks={socialLinks}
                languages={languages}
                hobbies={hobbies}
                certifications={certifications}
                techskill={techskill}
                OtherPersonalDetails={OtherPersonalDetails}
                ExtracurricularActivities={ExtracurricularActivities}
                CustomSection={CustomSection}

            />
        case "3":
            return <Template3
                resume={resume}
                personalInfoData={personalInfoData}
                workExpData={workExpData}
                eduData={eduData}
                projectData={projectData}
                skillSet={skillSet}
                socialLinks={socialLinks}
                languages={languages}
                hobbies={hobbies}
                certifications={certifications}
                techskill={techskill}
                OtherPersonalDetails={OtherPersonalDetails}
                ExtracurricularActivities={ExtracurricularActivities}
                CustomSection={CustomSection}
            />
        case "4":
            return <Template4
                resume={resume}
                personalInfoData={personalInfoData}
                workExpData={workExpData}
                eduData={eduData}
                projectData={projectData}
                skillSet={skillSet}
                socialLinks={socialLinks}
                languages={languages}
                hobbies={hobbies}
                certifications={certifications}
                techskill={techskill}
                OtherPersonalDetails={OtherPersonalDetails}
                ExtracurricularActivities={ExtracurricularActivities}
                CustomSection={CustomSection}
            />
        case "5":
            return <Template5
                resume={resume}
                personalInfoData={personalInfoData}
                workExpData={workExpData}
                eduData={eduData}
                projectData={projectData}
                skillSet={skillSet}
                socialLinks={socialLinks}
                languages={languages}
                hobbies={hobbies}
                certifications={certifications}
                techskill={techskill}
                OtherPersonalDetails={OtherPersonalDetails}
                ExtracurricularActivities={ExtracurricularActivities}
                CustomSection={CustomSection}
            />
        case "6":
            return <Template6
                resume={resume}
                personalInfoData={personalInfoData}
                workExpData={workExpData}
                eduData={eduData}
                projectData={projectData}
                skillSet={skillSet}
                socialLinks={socialLinks}
                languages={languages}
                hobbies={hobbies}
                certifications={certifications}
                techskill={techskill}
                OtherPersonalDetails={OtherPersonalDetails}
                ExtracurricularActivities={ExtracurricularActivities}
                CustomSection={CustomSection}
            />
        default:
            // Code to execute if none of the cases match
            break;
    }
}
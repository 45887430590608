import { useEffect, useState } from "react"
import Validate from "../../validation";
import axios from "axios";
import Cookies from "js-cookie";
import { apiUrl, aiAPIService, getCreditPoint, deductCreditPoints, getCreditPointDeducts, jwtVerify, validateEmail, validatePhoneNumber } from "../../function";
import { Link } from "react-router-dom"
import { Aisuggesstionmodal } from "../modal/Aimodal";
import 'react-responsive-modal/styles.css';
import '../css/model.css'
export default function Personaldetails({ onPersonaldetailsChange }) {
  const vld = new Validate();
  let x = localStorage.getItem("PD" + jwtVerify("userAuth").userId);
  let initialValues = {
    name: '',
    resumeHeadline: '',
    summary: '',
    email: '',
    countryCode: '',
    phone: '',
    profile_pic: '',
    workExperienceyear: '',
    workExperiencemonth: '',
  };
  const [year, setYear] = useState('');
  const [month, setMonth] = useState('');
  const [formValues, setFormValues] = useState(initialValues);
  const [profilePhoto, setProfilePhoto] = useState('');
  const [isModelButtonVisible, setIsModelButtonVisible] = useState(false);
  const [isModelButtonSummary, setIsModelButtonSummary] = useState(false);
  const [inputDataForModal, setInputDataForModal] = useState();
  const [aiSuggesstion, setAiSuggesstion] = useState();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [creditPoints, setCreditPoints] = useState(null);
  const [creditPointsDeduct, setCreditPointsDeduct] = useState(null);
  const [validEmailerror, setValidemail] = useState()
  const [validphoneerror, setValidphone] = useState()
  if (x != null) {
    initialValues = JSON.parse(x);
  }
  const [filePreview, setFilePreview] = useState('');
  async function getYM() {
    try {
      let response = await axios.get(apiUrl() + '/get-YM');
      let yearOption = response.data.ISyear.map((item, index) => {
        return <option key={index}>{item.year}</option>
      })
      setYear(yearOption)
      let monthOption = response.data.ISmonth.map((item, index) => {
        return <option key={index}>{item.month}</option>
      })
      setMonth(monthOption)
    } catch (error) {

    }
  }
  const handleFileChange = (event) => {
    let file = event.target.files[0];
    setProfilePhoto(file);
    if (file) {
      previewFile(file);
    }
  };
  const previewFile = (file) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      setFilePreview(event.target.result);
      setFormValues((prevValues) => ({
        ...prevValues,
        profile_pic: event.target.result
      }));
    };
    reader.readAsDataURL(file);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    if ((e.target.name == "resumeHeadline") && (e.target.value.length >= 20)) {
      setIsModelButtonVisible(true);
      setIsModelButtonSummary(false);
    }
    if ((e.target.name == "summary") && (e.target.value.length >= 20)) {
      setIsModelButtonSummary(true);
      setIsModelButtonVisible(false);
    }
  }
  const handleSubmit = async (e) => {
    let data = { ...formValues }
    if (!validateEmail(data.email)) {
      setValidemail("Please enter valid email!")
      return
    }
    else {
      setValidemail('')
    }
    if (!validatePhoneNumber(data.phone)) {
      setValidphone('Phone number must be 10 digit!')
      return
    }
    else {
      setValidphone('')
    }
    onPersonaldetailsChange(formValues);
    let token = Cookies.get('userAuth')
    let formData = new FormData();
    formData.append('profilePic', profilePhoto);
    delete data.photo
    for (var key in data) {
      var value = data[key];
      formData.append(key, value)
    }
    try {
      const response = await axios.post(apiUrl() + '/submit-personalDetails-resume',
        formData,
        { headers: { 'Authorization': `Bearer ${token}` } })
      if (response.data.statusCode == "200") {
      } else {
      }
    } catch (error) {

    }
  }
  const onOpenModal = (content, content_type) => {
    callAIApi(content, content_type);
    setOpen(true);
  }
  const onCloseModal = () => setOpen(false);
  const callAIApi = async (content, content_type) => {
    setIsLoading(true);
    let aiAPIRes = await aiAPIService(content, content_type);
    if (aiAPIRes) {
      setIsLoading(false)

      setAiSuggesstion(aiAPIRes);
    }
    else {
      setIsLoading(true);
    }
  }
  const getCreditP = async () => {
    let token = Cookies.get('userAuth')
    let creditPT = await getCreditPoint(token);
    if (creditPT) {
      setCreditPoints(creditPT);
    }
  }
  const getCreditPointDeduct = async () => {
    let creditPointDeduct = await getCreditPointDeducts();
    if (creditPointDeduct) {
      setCreditPointsDeduct(creditPointDeduct);
    }
  }
  const deductCreditPts = async (value) => {
    let token = Cookies.get('userAuth')
    if (creditPoints >= value) {
      let availCreditPoints = creditPoints - value;
      let updateCP = await deductCreditPoints(availCreditPoints, token);
      setCreditPoints(availCreditPoints);
      getCreditP();
    }
    else if (creditPoints == 0) {
      setCreditPoints(0);
    }
  };
  function getpersonalDetails() {
    let token = Cookies.get('userAuth');
    axios.get(apiUrl() + '/get-rusume-data?field=PD', {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => {
        if (response.data.statusCode == 200) {
          setFormValues(response.data.result[0]);
          onPersonaldetailsChange(response.data.result[0]);
        } else {
        }
      })
      .catch(error => {

      });
  }
  useEffect(() => {
    getYM();
    let x = localStorage.getItem("PD" + jwtVerify("userAuth").userId);

    if (jwtVerify("userAuth").pic != null && x == null) {
      if (jwtVerify("userAuth").pic == "") {
        setFilePreview('https://img.icons8.com/color/120/user.png')
      }
      else {

        setFilePreview(jwtVerify("userAuth").pic)
        setProfilePhoto(jwtVerify("userAuth").pic)
      }

    }
    if (x != null) {
      setFilePreview(initialValues.profile_pic)
      onPersonaldetailsChange(initialValues);
      setFormValues(initialValues)
    }
    else {
      getpersonalDetails()
    }
    // API call for getCreditPoint ----------------------
    getCreditP();
    getCreditPointDeduct();
  }, []);
  return (
    <>
      <section className="flex flex-col gap-3 rounded-md bg-white p-6 pt-4 shadow transition-opacity duration-200 undefined">
        <form>
          <div className="row">
            <div className="form-group col-sm-8 mt-3">
              <label htmlFor="exampleInputPassword1">Full Name</label>
              <input type="text"
                className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base resize-none overflow-hidden"
                id="exampleInputPassword1"
                name="name"
                placeholder="full name"
                value={formValues.name}
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-sm-4  mt-3">
              <label htmlFor="profilePhoto" className="profilePhoto" ><img style={{ height: "120px", width: "120px" }} src={filePreview} /> </label>
              <input className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base resize-none overflow-hidden"
                type="file" name="photo" onChange={handleFileChange}
                id="profilePhoto" />
            </div>
            <div className="form-group col-sm-6 mt-3">
              <label htmlFor="Email">Email</label>
              <input placeholder="Email" className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base resize-none overflow-hidden"
                id="Email"
                type="email"
                value={formValues.email}
                name="email"
                onChange={handleChange}
              />
              <span className="text-danger">{validEmailerror}</span>
            </div>
            <div className="form-group col-sm-6 mt-3">
              <label htmlFor="phone">Phone</label><br />
              <select name="countryCode" onChange={handleChange} value={formValues.countryCode} style={{ display: "inline" }} className="mt-1 py-2 border border-gray-300  text-gray-900 shadow-sm outline-none font-normal text-base resize-none overflow-hidden">
                <option value="+91">+91 IN</option>
                <option value="+1">+1 USA</option>
                <option value="+44">+44 UK</option>
                <option value="+81">+81 Jap</option>
                <option value="+86">+86 Chi</option>
                <option value="+49">+49 Ger</option>
              </select>
              <input style={{ display: "inline" }} placeholder="Phone" className="mt-1 px-1 py-2  border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base resize-none overflow-hidden"
                id="phone"
                type="text"
                value={formValues.phone}
                name="phone"
                onChange={handleChange}
              />
              <span className="text-danger">{validphoneerror}</span>
            </div>
            <div className="form-group col-sm-6 mt-3">
              <label htmlFor="workExp">Total Work Experience</label>
              <select
                className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base resize-none overflow-hidden"
                id="workExp"
                value={formValues.workExperienceyear}
                name="workExperienceyear"
                onChange={handleChange}
              >
                <option>Select Year</option>
                {year}
              </select>
            </div>
            <div className="form-group col-sm-6 mt-3">
              <label htmlFor="workExp"></label>
              <select
                className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base resize-none overflow-hidden"
                value={formValues.workExperiencemonth} name="workExperiencemonth" onChange={handleChange}
              >
                <option>Select Month</option>
                {month}
              </select>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <label className="text-base font-medium text-gray-700 col-span-full mt-3">Resume Headline  </label>
              </div>
              <div className="col-sm-6">
                {isModelButtonVisible ? (
                  creditPoints > 0 ? (
                    <a>
                      <button
                        type="button"
                        className="btn btn-link creds"
                        onClick={() => {
                          onOpenModal(formValues.resumeHeadline, 'resume_Headline');
                        }}
                      >
                        View Suggestion
                      </button>
                    </a>
                  ) : (
                    // <label className="text-base font-medium text-gray-700 col-span-full mt-3">Get More Credit Point</label>
                    <Link to='/pricing' className="btn btn-link nocreds">
                      Get More Credit Point</Link>
                  )
                ) : (
                  <p></p>
                )}
                {isModelButtonVisible ?
                  <Aisuggesstionmodal
                    isLoading={isLoading}
                    open={open}
                    aiSuggesstion={aiSuggesstion}
                    handlechangeAitext={handleChange}
                    closeModal={onCloseModal}
                    ondeductCreditPts={deductCreditPts}
                    for={"resumeHeadline"}
                    indexNum={0} />
                  : ''}
              </div>
            </div>
            <textarea name="resumeHeadline"
              className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base resize-none overflow-hidden"
              placeholder="Entrepreneur and educator obsessed with making education free htmlFor anyone"
              style={{ height: "88px" }} value={formValues.resumeHeadline} onChange={handleChange}>
            </textarea>
            <div className="row">
              <div className="col-sm-6">
                <label className="text-base font-medium text-gray-700 col-span-full mt-3">Professional Summary </label>
              </div>
              <div className="col-sm-6 ">
                {/* {isModelButtonSummary ? (<a><button type="button" className=" link-primary text-base font-medium text-gray-700 col-span-full mt-3" onClick={() => {
               onOpenModal(formValues.summary, 'resume_summary')
               }}>Pre-written phrases</button></a>) : (
               <p></p>
               )} */}
                {isModelButtonSummary ? (
                  creditPoints > 0 ? (
                    <a>
                      <button
                        type="button"
                        className="btn btn-link creds"
                        onClick={() => {
                          onOpenModal(formValues.summary, 'summary');
                        }}
                      >
                        View Suggestion
                      </button>
                    </a>
                  ) : (
                    <Link to='/pricing' className="btn btn-link nocreds">
                      No Credit get more</Link>
                  )
                ) : (
                  <p></p>
                )}
                {isModelButtonSummary ?
                  <Aisuggesstionmodal
                    isLoading={isLoading}
                    open={open}
                    aiSuggesstion={aiSuggesstion}
                    handlechangeAitext={handleChange}
                    closeModal={onCloseModal}
                    ondeductCreditPts={deductCreditPts}
                    for={"summary"}
                    indexNum={0}
                  />
                  : ''}
              </div>
            </div>
            <textarea name="summary"
              className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base resize-none overflow-hidden"
              placeholder="Entrepreneur and educator obsessed with making education free htmlFor anyone"
              style={{ height: "88px" }} value={formValues.summary} onChange={handleChange}
            >
            </textarea>
          </div>
        </form>
      </section>
      <div className=" flex justify-end">
        <button onClick={handleSubmit} type="submit" className="flex items-center rounded-md bg-white py-2 pl-3 pr-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6"></path>
          </svg>
          Save
        </button>
      </div>
    </>
  );
}
import "./css/faq.css";
export default function Faq(){
    return(
        <section id="faq" className="faq">

        <div className="container" data-aos="fade-up">
  
          <header className="section-header">
            <h2>F.A.Q</h2>
            <p>Frequently Asked Questions</p>
          </header>
  
          <div className="row">
            <div className="col-lg-6">
              <div className="accordion accordion-flush" id="faqlist1">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-1">
                      Is it free to use this resume builder?
                    </button>
                  </h2>
                  <div id="faq-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div className="accordion-body">
                    Yes, it's free to use our resume builder with limited AI edits. Free users can download their resume in PDF format.
                    </div>
                  </div>
                </div>
  
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-2">
                    What are the limitations of the free version?
                    </button>
                  </h2>
                  <div id="faq-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div className="accordion-body">
                    The free version allows you to create and format your resume. However, advanced AI content suggestions and the ability to download in various formats are available to premium users.
                    </div>
                  </div>
                </div>
  
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-3">
                    How do I get started with creating my resume?
                    </button>
                  </h2>
                  <div id="faq-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div className="accordion-body">
                    Simply sign up for an account, choose a template, and start customizing your resume with your information                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-4">
                    Can I save and download my resume in different formats as a free user?
                    </button>
                  </h2>
                  <div id="faq-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div className="accordion-body">
                    As a free user, you can save and download your resume in PDF format. Premium users have the flexibility to download in multiple formats                    </div>
                  </div>
                </div>
                
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq-content-5">
                    Is my personal information safe on this platform?
                    </button>
                  </h2>
                  <div id="faq-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist1">
                    <div className="accordion-body">
                    Yes, we take data privacy seriously. Your information is securely stored and not shared with third parties                    </div>
                  </div>
                </div>
  
              </div>
            </div>
  
            <div className="col-lg-6">
              <div className="accordion accordion-flush" id="faqlist2">
  
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-1">
                    What makes this resume builder unique?
                    </button>
                  </h2>
                  <div id="faq2-content-1" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div className="accordion-body">
                    Our resume builder offers intelligent AI suggestions for content enhancement, helping you create a standout resume.                    </div>
                  </div>
                </div>
  
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-2">
                    Can I update my resume after I've created it?
                    </button>
                  </h2>
                  <div id="faq2-content-2" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div className="accordion-body">
                    Absolutely, you can log in anytime to make updates, edits, or create multiple versions of your resume.
                    </div>
                  </div>
                </div>
  
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-3">
                    Is there a mobile app available for this service?
                    </button>
                  </h2>
                  <div id="faq2-content-3" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div className="accordion-body">
                    Currently, we offer a mobile-responsive website that works well on smartphones and tablets.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-4">
                    Do you offer any job search or application features?
                    </button>
                  </h2>
                  <div id="faq2-content-4" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div className="accordion-body">
                    While we primarily focus on resume creation, we may consider adding job search and application features in the future.
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#faq2-content-5">
                    How do I upgrade to the premium version for full AI capabilities?
                    </button>
                  </h2>
                  <div id="faq2-content-5" className="accordion-collapse collapse" data-bs-parent="#faqlist2">
                    <div className="accordion-body">
                    You can upgrade to the premium version from your account dashboard to access advanced AI content suggestions, the ability to download in various formats, and additional features.
                    </div>
                  </div>
                </div>

              </div>
            </div>
  
          </div>
  
        </div>
  
      </section>
    )
}
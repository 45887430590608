import "./css/header.css";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import { getCookie } from "../function";

export default function Header() {
  const [jwt, setJwt] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    let cookie = Cookies.get('userAuth')
    if (cookie != undefined) {
      let decode = jwtDecode(Cookies.get('userAuth'))
      setJwt(decode)
    }


  }, [])
  const [profileClick, setprofileClick] = useState(false)
  const [mobileMenu, setmobileMenu] = useState('')
  const heandelprofileClick = (x) => {
    setprofileClick(x)
  }
  const heandelmobileMenu = (x) => {
    setmobileMenu(x)
  }
  const logOut = () => {
    Cookies.remove('userAuth');
    window.location.reload();
  }
  return (

    <header id="header" className="header fixed-top">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-between">

        <Link to="/" className="logo d-flex align-items-center">
          <img src="assets/img/logo/thejobsearch_logo.png" alt="" />
        </Link>

        <nav id="navbar" className="navbar" >
          <ul>
            <li><Link to="/" className="">Home</Link></li>
            <li> <Link to="/about" className="">About</Link></li>
            <li><Link to="/contactus" className="" >Contact</Link></li>
            <li><Link to="/pricing" className="" >Pricing</Link></li>
            {(
              Cookies.get('userAuth') == undefined ?
                <>
                  <li> <Link to="/register" className="">Register</Link></li>
                  <li> <Link to="/login" className="">Login</Link></li>
                  {/* <li> <Link to="/dashboard"><img style={{ borderRadius: "50%" }} src="assets/img/testimonials/testimonials-1.jpg" height={40} width={40} alt="" /></Link></li> */}
                </>
                : <li> <Link onClick={() => { heandelprofileClick(true) }} to="#" className="">Hii &nbsp;{jwt.pic ? <img className="profile-pic" src={jwt.pic} /> : jwt.name}</Link></li>
            )}
            <Link to="/rdesign" className="getstarted ">Get Started</Link>

          </ul>
          <i className="bi bi-list mobile-nav-toggle" onClick={() => { heandelmobileMenu(true) }}></i>
        </nav>

      </div>
      <div style={{ display: profileClick ? 'block' : 'none' }} className="profile-click ">
        <button onClick={() => { heandelprofileClick(false) }}><span className="bi bi-x"></span></button>
        <ul>
          <li><Link onClick={() => { heandelprofileClick(false) }} to="/dashboard" className=" ">Dashboard</Link></li>
          <li><Link onClick={() => { heandelprofileClick(false); logOut() }} to="#" className=" ">Logout</Link></li>
        </ul>
      </div>
    </header>
  );
}
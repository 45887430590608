import { useEffect, useState} from 'react';
import {getCreditPoint, deductCreditPoints,getCreditPointDeducts} from "../../function";
import { Modal } from 'react-responsive-modal';
import LoaderComp from '../form/LoaderComp'

function Aisuggesstionmodal(props) {
    const [creditPoints, setCreditPoints] = useState(null);
    const [creditPointsDeduct, setCreditPointsDeduct] = useState(null);

    const getCreditPointDeduct = async () => {
        let creditPointDeduct = await getCreditPointDeducts();
        if (creditPointDeduct) {
          setCreditPointsDeduct(creditPointDeduct);
        }
      }

    useEffect(()=>{
        getCreditPointDeduct()
    },[])
    return (
        <>
            {props.isLoading ? (<Modal open={props.open} onClose={props.closeModal} showCloseIcon='false' closeIcon=' ' classNames={{
                overlayAnimationIn: 'customEnterOverlayAnimation',
                overlayAnimationOut: 'customLeaveOverlayAnimation',
                modalAnimationIn: 'customEnterModalAnimation',
                modalAnimationOut: 'customLeaveModalAnimation',
            }}
                animationDuration={800}
                center ><LoaderComp /></Modal>) : <>
                {props.aiSuggesstion && (
                    <Modal open={props.open} onClose={props.closeModal} classNames={{
                        overlayAnimationIn: 'customEnterOverlayAnimation',
                        overlayAnimationOut: 'customLeaveOverlayAnimation',
                        modalAnimationIn: 'customEnterModalAnimation',
                        modalAnimationOut: 'customLeaveModalAnimation',
                    }}
                        animationDuration={800}
                        center >
                        {props.aiSuggesstion != undefined ? (<div style={{ top: '15px', position: 'relative' }}>
                            {Object.entries(props.aiSuggesstion).map(([key, value]) => (
                                <button key={key} value={value.replace("\n\n", "")}
                                 onClick={(e) => {
                                    props.handlechangeAitext(e,props.indexNum);
                                    props.closeModal()
                                    props.ondeductCreditPts(creditPointsDeduct);
                                }} name={props.for} className="phrases">{value}</button>
                            ))}
                        </div>) : ''}
                    </Modal>
                )}
            </>}
        </>
    )

}

export { Aisuggesstionmodal }
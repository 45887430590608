
export default function Template2({ personalInfoData, workExpData, eduData, projectData,
  skillSet, socialLinks, languages, hobbies, certifications, techskill, OtherPersonalDetails,
  ExtracurricularActivities, CustomSection }) {
  let expData = workExpData.data;
  let educationData = eduData.data;
  let personalData = personalInfoData.inputValue;
  let projectDetails = projectData.data;
  let skillSetData = skillSet.data;
  let socialLinksData = socialLinks.data;
  let languagesData = languages.data;
  let hobbiesData = hobbies.data;
  let certification = certifications.data
  let techSkill = techskill.data
  let OPD = OtherPersonalDetails.data
  let ECA = ExtracurricularActivities.data
  let CS = CustomSection.data


  const containerStyle = {
    margin: 0,
    display: 'flex',
    fontFamily: 'Roboto, sans-serif',
  };

  const sideStyle = {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#35556B',
    width: '40%',
    minHeight: '98.5vh',
    height: 'auto'
  };

  const picStyle = {
    margin: '2rem',
    height: '9rem',
    width: '9rem',
    borderRadius: '50%',
    border: '.3rem solid white',
    alignSelf: 'center',
    overflow: 'hidden',
  };

  const JobName = {
    display: 'flex',

  }

  const sideTagsStyle = {
    backgroundColor: '#EBE768',
    width: '80%',
    padding: '10px',
    textAlign: 'center',
    borderRadius: '0px 50px 0px 0px',
    fontSize: '22px',
    marginBottom: '0px',
    color: '#35556B',
  };

  const objectiveStyle = {
    margin: '5%',
    fontSize: '18px',
    color: "#bdc8cf",
    overflow: 'hidden',
    maxheight: '70px',
  };

  const skillStyle = {
    fontSize: '18px',
    alignSelf: 'center',
    margin: '15px 10px 0px 10px',
    display: 'inline',
    backgroundColor: 'white',
    borderRadius: '5px',
    padding: '5px'
  };

  const mainStyle = {
    display: 'flex',
    flexDirection: 'column',
    width: '60%',
    height: 'auto',

  };

  const headerStyle = {
    width: '100%',
    height: 'auto',
    backgroundColor: '#78BDE9',
    textAlign: 'center',
  };

  const nameStyle = {
    color: 'white',
    fontSize: '42px',
    marginBottom: '0px',
  };

  const profileStyle = {
    color: '#4A7A98',
    marginBottom: '0',
  };

  const detailsStyle = {
    display: 'flex',
    justifyContent: 'space-evenly',
    color: 'white',
  };

  const detailGroupingStyle = {
    display: 'flex',
    position: 'relative',
  };

  const iconStyle = {
    marginTop: '22px',
    marginRight: '10px',
  };

  const headingsStyle = {
    backgroundColor: '#4A7A98',
    padding: '10px',
    fontSize: '22px',
    width: '90%',
    borderRadius: '0px 60px 0px 0px',
    marginLeft: '10px',
    borderRight: '.9rem solid #78BDE9',
    color: 'white',
  };

  const keyFeaturesStyle = {
    alignSelf: 'self-start',
    margin: '0px',
    fontSize: '18px',
    listStyle: 'none',
    color: "#071330"
  };

  const keyHeadingsStyle = {
    fontWeight: 'bolder',
  };

  const eduStyle = {
    alignSelf: 'self-start',
    marginLeft: '15px',
    fontSize: '16px',
    color: "#071330"

  };

  const icoStyle = {
    marginRight: '10px',
  };
  return (
    <div style={containerStyle} className="wh">
      {personalData ? (
        <section style={sideStyle} className="side">
          <img style={picStyle} src={personalData.profile_pic} />
          <h1 style={sideTagsStyle} className="side_tags">
            About me
          </h1>
          <p style={objectiveStyle} className="objective">
            {personalData.summary}
          </p>

          {skillSetData ? (
            skillSetData.map((item, index) => (
              <div>
                {(index == "0") ? (<h1 style={sideTagsStyle} className="side_tags">{item.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <h4 style={skillStyle}>{item.skillName}</h4>
                </div>
              </div>
            ))
          ) : (
            <p style={{ position: "absolute" }}></p>
          )}

          {techSkill ? (
            techSkill.map((item, index) => (
              <div>
                {(index == "0") ? (<h1 style={sideTagsStyle} className="side_tags">{item.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <h4 style={skillStyle}>{item.skillName}</h4>
                </div>
              </div>
            ))
          ) : (
            <p style={{ position: "absolute" }}></p>
          )}


          {languagesData ? (
            languagesData.map((item, index) => (
              <div>
                {(index == "0") ? (<h1 style={sideTagsStyle} className="side_tags">{item.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <h4 style={skillStyle}>{item.language}</h4>
                </div>
              </div>
            ))
          ) : (
            <p style={{ position: "absolute" }}></p>
          )}

          {hobbiesData ? (
            hobbiesData.map((item, index) => (
              <div>
                {(index == "0") ? (<h1 style={sideTagsStyle} className="side_tags">{item.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <h4 style={skillStyle}>{item.hobbies}</h4>
                </div>
              </div>
            ))
          ) : (
            <p style={{ position: "absolute" }}></p>
          )}
          {socialLinksData ? (
            socialLinksData.map((item, index) => (
              <div>
                {(index == "0") ? (<h1 style={sideTagsStyle} className="side_tags">{item.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  <h4 style={skillStyle}>{item.socialLinks}</h4>
                </div>
              </div>
            ))
          ) : (
            <p style={{ position: "absolute" }}></p>
          )}

        </section>
      ) : <p></p>}
      <section style={mainStyle} className="main">
        {personalData ? (
          <header style={headerStyle} className="header">

            <h1 style={nameStyle} className="name">
              {personalData.name}
            </h1>
            <h2 style={profileStyle} className="profile">
              {personalData.resumeHeadline}
            </h2>
            <div style={detailsStyle} className="details">
              <div style={detailGroupingStyle} className="detail_grouping">
                <i style={iconStyle} className="fas fa-envelope"></i>
                <h5 className="email">{personalData.email}</h5>
              </div>
              <div style={detailGroupingStyle} className="detail_grouping">
                <i style={iconStyle} className="fas fa-phone"></i>
                <h5 className="ph_no">{personalData.phone}</h5>
              </div>
              <div style={detailGroupingStyle} className="detail_grouping">
                <i style={iconStyle} className="fas fa-calendar"></i>
                <h5 className="exp">{personalData.workExperienceyear}  {personalData.workExperiencemonth}</h5>
              </div>
            </div>
          </header>
        ) : <p></p>}

        {expData ? (
          expData.map((item, index) => (
            <div>
              {(index == "0") ? (<h1 style={headingsStyle} className="headings">{item.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
              <ul style={keyFeaturesStyle} className="key_features">
                <li style={keyHeadingsStyle} className="key_headings">
                  <div style={JobName}>
                    <span>{item.company} - {item.jobTitle}</span>
                    <span style={{ marginLeft: '100px' }}>  {item.workSinceYear} - {item.worksincemonth} To {item.currentCompany === 'No' ? `${item.workSincetoYear} - ${item.worksincetomonth}` : `${item.workedTill}`}</span>
                  </div>
                </li>
                <p>{item.descp}</p>
              </ul>
            </div>
          ))
        ) : (
          <p style={{ position: "absolute" }}></p>
        )}

        {educationData ? (
          educationData.map((value, index) => (
            <div style={{ width: "100%" }}>
              {(index == "0") ? (<h1 style={headingsStyle} className="headings">{value.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
              <div style={eduStyle} className="edu">
                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{value.courseName} - {value.specialization}</span>
                <div>{value.markes} {value.gradingSystem}</div>
                <h3>{value.school} - {value.courseType}</h3>
                <div>{value.date}</div>
                <p>{value.descp}</p>

              </div>
            </div>
          ))
        ) : (
          <p style={{ position: "absolute" }}></p>
        )}
        {projectDetails ? (
          projectDetails.map((item, index) => (
            <div>
              {(index == "0") ? (<h1 style={headingsStyle} className="headings">{item.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
              <ul style={keyFeaturesStyle} className="key_features">
                <li style={keyHeadingsStyle} className="key_headings">
                  {item.projectName} - {item.projectRole}<br></br>
                  <span style={{ fontSize: '14px', fontWeight: 'normal' }}>at {item.clientCollege} in {item.year} / Guided by {item.ManagerGuide}</span>
                </li>
                <p>{item.projectDesc}</p>
              </ul>
            </div>
          ))
        ) : (
          <p style={{ position: "absolute" }}></p>
        )}

        {certification ? (
          certification.map((item, index) => (
            <div>
              {(index == "0") ? (<h1 style={headingsStyle} className="headings">{item.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
              <ul style={keyFeaturesStyle} className="key_features">
                <li style={keyHeadingsStyle} className="key_headings">
                  {item.certificationCourse}   (Id -{item.certificateID})<br></br>
                  <span style={{ fontSize: '14px', fontWeight: 'normal' }}>Issued on:{item.issueDateMonth} {item.issueDateYear} | Expires on:{item.expiryDateMonth} {item.expiryDateYear}</span>
                </li>
                <p>{item.CertificateVerificationURL}</p>
              </ul>
            </div>
          ))
        ) : (
          <p style={{ position: "absolute" }}></p>
        )}

        {ECA ? (
          ECA.map((value, index) => (
            <div>
              {(index == "0") ? (<h1 style={headingsStyle} className="headings">{value.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
              <div style={eduStyle} className="edu">
                <span style={{ fontWeight: 'bold', fontSize: '18px' }}>{value.activityName}</span>
                <p>{value.descp}</p>
              </div>
            </div>
          ))
        ) : (
          <p style={{ position: "absolute" }}></p>
        )}

        {CS ? (
          CS.map((value) => (
            <div>
              <h1 style={headingsStyle} className="headings">
                {value.heading}
              </h1>

              <div style={eduStyle} className="edu">
                <h3>{value.activityName}</h3>
                <p>{value.descp}</p>
              </div>
            </div>
          ))
        ) : (
          <p style={{ position: "absolute" }}></p>
        )}

        {OPD ? (
          OPD.map((value, index) => (
            <div >
              {(index == "0") ? (<h1 style={headingsStyle} className="headings">{value.heading}</h1>) : (<p style={{ position: "absolute" }}></p>)}
              <table style={{ width: '60%', fontWeight: 'bold' }}>
                <tr>
                  <td>Marritial Status :</td>
                  <td>{value.MaritalStatus}</td>
                </tr>
                <tr>
                  <td>Nationality :</td>
                  <td>{value.Nationality}</td>
                </tr>
                <tr>
                  <td>address :</td>
                  <td>{value.address}</td>
                </tr>
                <tr>
                  <td>City :</td>
                  <td>{value.city}</td>
                </tr>
                <tr>
                  <td>Country :</td>
                  <td>{value.country}</td>
                </tr>
                <tr>
                  <td>Pin Code :</td>
                  <td>{value.pincode}</td>
                </tr>
                <tr>
                  <td>D.O.B. :</td>
                  <td>{value.dob}</td>
                </tr>
                <tr>
                  <td>Gender :</td>
                  <td>{value.gender}</td>
                </tr>
              </table>
            </div>
          ))
        ) : (
          <p style={{ position: "absolute" }}></p>
        )}


      </section>
    </div>

  );
}
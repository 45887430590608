export default function Benefits() {
  return (
    <section id="features" className="features">
      <div className="container" data-aos="fade-up">
        <header className="section-header">
          <h2>Benefits</h2>
          <p>Here are our Benefits</p>
        </header>
        <div className="row feture-tabs mt-2 " data-aos="fade-up">
          <div className="col-lg-6">
            <div className="tab-content">
              <div className="tab-pane fade show active " id="tab1">
                <h4 style={{ color: "#FF3052", fontSize: "20px" }}>1. Enhanced Content</h4>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-check2-square"></i>
                  <p style={{ color: "#012970", margin: "5px", borderRadius: "3px", fontSize: "17px", fontWeight: "600" }} >Optimize content for maximum impact and relevance</p>
                </div>
                <h4 style={{ color: "#FF3052", fontSize: "20px", marginTop: "20px" }}>2. Stunning Templates</h4>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-check2-square"></i>
                  <p style={{ color: "#012970", margin: "5px", borderRadius: "3px", fontSize: "17px", fontWeight: "600" }} >Choose from a variety of visually appealing resume templates</p>
                </div>
                <h4 style={{ color: "#FF3052", fontSize: "20px", marginTop: "20px" }}>3. AI Guidance</h4>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-check2-square"></i>
                  <p style={{ color: "#012970", margin: "5px", borderRadius: "3px", fontSize: "17px", fontWeight: "600" }}>Receive intelligent suggestions for content and formatting improvements.</p>
                </div>
                <h4 style={{ color: "#FF3052", fontSize: "20px", marginTop: "20px" }}>4. Job-Specific Keywords</h4>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-check2-square"></i>
                  <p style={{ color: "#012970", margin: "5px", borderRadius: "3px", fontSize: "17px", fontWeight: "600" }}>Ensure your resume includes key industry-specific keywords.</p>
                </div>
                <h4 style={{ color: "#FF3052", fontSize: "20px", marginTop: "20px" }}>5. Instant Preview</h4>
                <div className="d-flex align-items-center mb-2">
                  <i className="bi bi-check2-square"></i>
                  <p style={{ color: "#012970", margin: "5px", borderRadius: "3px", fontSize: "17px", fontWeight: "600" }}>See real-time changes to your resume as you edit.</p>
                </div>
                <h4 style={{ color: "#FF3052", fontSize: "20px", marginTop: "20px" }}>6. Effortless Editing</h4>
                <div className="d-flex align-items-center">
                  <i className="bi bi-check2-square"></i>
                  <p style={{ color: "#012970", margin: "5px", borderRadius: "3px", fontSize: "17px", fontWeight: "600" }}>User-friendly interface for easy resume customization.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <img src="assets/img/features-2.png" className="img-fluid" alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}
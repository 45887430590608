import "../css/bulidresume.css";
import { jwtVerify } from "../function";
import Personaldetails from "../components/form/personalDetails";
import Workexpe from "../components/form/workExpe";
import Education from "../components/form/education";
import Project from "../components/form/project";
import Skills from "../components/form/skills";
import { useState } from "react";
import Resumeview from "../resumeTemplates/resumeView";
import Social from "../components/form/Social";
import Language from "../components/form/Language";
import Hobbies from "../components/form/Hobbies";
import Certifications from "../components/form/Certifications";
import Techskills from "../components/form/TechnicalSkills";
import Otherpersonaldetails from "../components/form/Otherpersonaldetails";
import Extracurricularactivities from "../components/form/ExtracurricularActivities";
import Customsection from "../components/form/CustomSection";
import { useEffect } from "react";
import { apiUrl } from "../function";
import axios from "axios";
export default function Buildresume() {
  var urlParams = new URLSearchParams(window.location.search);
  var paramValue = urlParams.get('resume');
  localStorage.setItem("resumeId", paramValue);
  const [personalInfoData, setPersonalInfoData] = useState({});
  const [eduData, setEduData] = useState('');
  const [workExpData, setworkExpData] = useState('');
  const [projectData, setprojectData] = useState('');
  const [skillSet, setskillSet] = useState('');
  const [socialLinks, setsocialLinks] = useState('');
  const [languages, setlanguages] = useState('');
  const [hobbies, sethobbies] = useState('');
  const [certifications, setCertification] = useState('')
  const [techskill, setTechskill] = useState('')
  const [OtherPersonalDetails, setOtherPersonalDetailsChange] = useState('')
  const [ExtracurricularActivities, setExtracurricularActivities] = useState('')
  const [CustomSection, setCustomSection] = useState('')
  useEffect(() => {
  }, [])
  const handlePersonaldetailsChange = (inputValue) => {
    localStorage.setItem("PD" + jwtVerify("userAuth").userId, JSON.stringify(inputValue));
    setPersonalInfoData((prevInputData) => ({
      ...prevInputData,
      inputValue,
    }));
  };
  const handleWorkexpeChange = (data) => {
    localStorage.setItem("WXP" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setworkExpData((prevInputData) => ({
      ...prevInputData, data,
    }));
  };


  const handleEducationChange = (data) => {
    localStorage.setItem("EDU" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setEduData((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleProjectChange = (data) => {
    localStorage.setItem("PRJ" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setprojectData((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleSkillChange = (data) => {
    localStorage.setItem("SKL" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setskillSet((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleSocialChange = (data) => {
    localStorage.setItem("SLNk" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setsocialLinks((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleLanguageChange = (data) => {
    localStorage.setItem("LNG" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setlanguages((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleHobbiesChange = (data) => {
    localStorage.setItem("HOB" + jwtVerify("userAuth").userId, JSON.stringify(data));
    sethobbies((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleCertificationChange = (data) => {
    localStorage.setItem("CRTF" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setCertification((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handelTechSkillChange = (data) => {
    localStorage.setItem("TECHSKL" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setTechskill((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handleOtherPersonalDetailsChange = (data) => {
    localStorage.setItem("OTPD" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setOtherPersonalDetailsChange((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handlExtracurricularActivitiesChange = (data) => {
    localStorage.setItem("Extracurricular" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setExtracurricularActivities((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  const handelCustomSection = (data) => {
    localStorage.setItem("CSTSEC" + jwtVerify("userAuth").userId, JSON.stringify(data));
    setCustomSection((prevInputData) => ({
      ...prevInputData, data,
    }));
  };
  return (
    <main className="relative h-full w-full overflow-hidden bg-gray-50" style={{ marginTop: "90px" }}>
      <div className="grid grid-cols-3 md:grid-cols-6">
        <div className="col-span-3">
          <div className="flex justify-center scrollbar scrollbar-track-gray-100 scrollbar-w-3 md:h-[calc(100vh-var(--top-nav-bar-height))] md:justify-end md:overflow-y-scroll">
            <section className="flex max-w-2xl flex-col gap-8 p-[var(--resume-padding)]">
              <Personaldetails onPersonaldetailsChange={handlePersonaldetailsChange} />
              <Education onEducationChange={handleEducationChange} />
              <Workexpe onWorkExpeChange={handleWorkexpeChange} />
              <Certifications onCertificationChange={handleCertificationChange} />
              <Project onProjectChange={handleProjectChange} />
              <Skills onSkillChange={handleSkillChange} />
              <Techskills onTechSkillChange={handelTechSkillChange} />
              <Social onSocialChange={handleSocialChange} />
              <Language onLanguageChange={handleLanguageChange} />
              <Otherpersonaldetails onOtherPersonalDetails={handleOtherPersonalDetailsChange} />
              <Hobbies onHobbiesChange={handleHobbiesChange} />
              <Extracurricularactivities onExtracurricularactivitiesChange={handlExtracurricularActivitiesChange} />
              <Customsection onCustomSectionChange={handelCustomSection} />
            </section>
            <div className="invisible shrink-[10000] grow hidden md:block" style={{ maxWidth: "50px", minWidth: "0px" }}>
            </div>
          </div>
        </div>
        <Resumeview resume={paramValue}
          personalInfoData={personalInfoData}
          workExpData={workExpData}
          eduData={eduData}
          projectData={projectData}
          skillSet={skillSet}
          socialLinks={socialLinks}
          languages={languages}
          hobbies={hobbies}
          certifications={certifications}
          techskill={techskill}
          OtherPersonalDetails={OtherPersonalDetails}
          ExtracurricularActivities={ExtracurricularActivities}
          CustomSection={CustomSection}
        />
      </div>
    </main>
  )
}
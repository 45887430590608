import "../css/login.css";
import React, { useState, useEffect } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import axios from "axios";
import Cookies from 'js-cookie';
import { apiUrl } from "../function";
import { useNavigate, Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Input from '@mui/material/Input';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';


export default function LoginPage() {

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const navigate = useNavigate();
  useEffect(() => {
    if (Cookies.get('userAuth') != undefined) {
      navigate(`/`, { replace: true });
      return;
    }
  }, [])
  const [inputValues, setInputValues] = useState({
    email: '',
    password: ''
  });

  const [msg, setMsg] = useState('')
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  //google Login..............................................
  const handleLogin = async (credentialResponse) => {
    var obj = jwtDecode(credentialResponse.credential);
    if (obj.email_verified) {

      try {
        const response = await axios.post(apiUrl() + '/register-google-login', {
          name: obj.name,
          email: obj.email,
          picture: obj.picture,
          password: ''
        })
        if (response.data.statusCode == "200") {

          Cookies.set('userAuth', response.data.token, { expires: 365 });

          window.location.reload()
        }
      } catch (error) {
        console.error(error);
      }
    }

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputValues.email != '' && inputValues.password != '') {
      setMsg('')
      try {
        const response = await axios.post(apiUrl() + '/login', {
          inputValues
        })
        if (response.data.statusCode == "200") {
          Cookies.set('userAuth', response.data.Token, { expires: 365 });
          window.location.reload()
        }
        else {
          setMsg(response.data.status)
        }

      } catch (error) {
        console.error(error);
      }
    }
    else {
      setMsg('All field are required !')
    }
  };

  return (

    <div>
      <div className="login">

        <div className="login-container">
          <h1>Welcome to AI Powered Resume builder</h1>
          <form onSubmit={handleSubmit}>
            <TextField sx={{ mb: 1, width: '25ch', }} type="email" id="standard-basic" label="Email" name="email" variant="standard" value={inputValues.email}
              onChange={handleInputChange} />
            <FormControl sx={{ mb: 1, width: '25ch' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password">Password</InputLabel>
              <Input
                id="standard-adornment-password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                onChange={handleInputChange}
                value={inputValues.password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl><br></br>
            <span className="text-danger">{msg}</span><br></br>
            <Button type="submit" variant="contained" style={{ padding: "10px", width: "25ch" }}>Login</Button>
            <br></br>
            <a>  <Link to="/forget-password">forget password</Link></a>

          </form>
          <div className="or-divider">
            <span>Or</span>
          </div>
          <div className="google-login" style={{ display: 'flex', justifyContent: 'center' }}>
            <GoogleOAuthProvider clientId="923492471250-tqdrgh8urq1qefcf5c2u552qsojfe9r6.apps.googleusercontent.com"  >
              <GoogleLogin onSuccess={handleLogin} />
            </GoogleOAuthProvider>
          </div>
        </div>

      </div>

    </div>
  );
};
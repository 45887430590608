import { Margin } from "@mui/icons-material";
import TextEditor from "../components/TextEditor";
import '../components/css/TextEditor.css';

export default function CoverLetter() {
    return (
        <div className="cvr">
            <h2 className="nmehd">Cover Letter</h2>
            <TextEditor />
        </div>
    )
}
import Sidebarmenue from "../components/sidebar"
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from "../function";
import Cookies from "js-cookie";
import { useNavigate, Link } from "react-router-dom";
export default function Creditpoints() {
    const [credits, setCredits] = useState(0)

    const getcredits = async () => {
        try {
            let authToken = Cookies.get('userAuth');
            let headers = {
                Authorization: `Bearer ${authToken}`,
            };
            let response = await axios.get(apiUrl() + '/get-user-credit-point', { headers });
            if (response.data.statusCode == 200) {
                setCredits(response.data.result[0].credit_point)
            }
            else {

            }
        } catch (error) {
            console.error(error);
        }

    };

    useEffect(() => {
        getcredits()
    }, [])
    return (
        <>
            <div style={{ display: 'flex', height: '100%', minHeight: '400px', marginTop: "100px" }}>
                <Sidebarmenue />

                <div style={{ width: "80%", height: "100vh", backgroundColor: "white", boxShadow: "0 3px 7px rgba(0, 0, 0, 0.2)", display: "flex", flexDirection: "column", alignItems: "center" }} className="container">
                    <h4 style={{ fontSize: "25px", fontFamily: "Verdana", margin: "20px", fontWeight: "bolder", color: "#0D3779" }}>AVAILABLE CREDIT POINTS</h4>
                    <div class="card text-center" style={{ width: '18rem' }}>
                        <div class="card-body">
                            <h5 class="card-title" style={{ padding: '15px', fontWeight: "bolder", }}>{credits}</h5>
                            <Link to="/pricing" class="btn-btn-blue">ADD CREDITS</Link>
                        </div>

                    </div>

                </div>
            </div>

        </>
    )
}
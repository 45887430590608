import axios from "axios";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
const apiUrl = () => {
      return "https://api.vishwanathpater.com";
  //  return "http://localhost:4000";
}
const getCookie = function (name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
}
const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
//---------AI API service ---------------------------------------------------------
const aiAPIService = async function (content, content_type) {
    const apiKey = process.env.REACT_APP_API_KEY;
    const aiApiUrl = process.env.REACT_APP_API_URL;
    const headers = {
        // "x-api-key": "Ta8ZPAJ9Ab86xcKinXeYU8jkz1SWQyhA5zk5Gugh",
        "x-api-key": apiKey,
        "Content-Type": "application/json"
    }
    var data = {
        "user_input": content,
        "content_type": content_type,
        "max_words": "50"
    }
    try {
        const response = await axios.post(aiApiUrl, data, { headers: headers });
        console.log('-------apiRes---', response);
        let apiRes = JSON.parse(response.data.body);

        return apiRes;
    } catch (error) {
        console.error(error);
        return error;
    }
}
//------------ get INITIAL CREDIT POINT--------------------------------------------
const getCreditPoint = async function (token) {
    try {
        const response = await axios.get(apiUrl() + '/get-credit-point', { headers: { 'Authorization': `Bearer ${token}` } });
        return response.data.creditPoint.credit_point;
    } catch (error) {
        console.error(error);
        return error;
    }
}
//------------ DEDUCT CREDIT POINT--------------------------------------------
const deductCreditPoints = async function (value, token) {
    console.log('---deductCreditPoints--', value);
    try {
        const response = await axios.post(apiUrl() + '/update-credit-point', { value: value }, { headers: { 'Authorization': `Bearer ${token}` } });
        return response;
    } catch (error) {
        console.error(error);
        return error;
    }
}
//------------ get INITIAL CREDIT POINT DEDUCT--------------------------------------------
const getCreditPointDeducts = async function () {
    try {
        const response = await axios.get(apiUrl() + '/get-default-credit-point-deduct');
        return response.data.defaultCreditPointDeduct.credit_points_deduct;
    } catch (error) {
        console.error(error);
        return error;
    }
}
const isDigitInRange = (number) => {
    if (Number.isNaN(number) || number < 0 || number > 10) {
        return false;
    }
    return true;
}
const jwtVerify = (cookieName) => {
    let cookie = Cookies.get(cookieName)
    if (cookie != undefined) {
        let decode = jwtDecode(Cookies.get(cookieName))
        return decode
    }
    else {
        return undefined
    }
}
const monthswithNum = (montinChar) => {
    let months_dict = {
        'Jan': 1,
        'Feb': 2,
        'Mar': 3,
        'Apr': 4,
        'May': 5,
        'Jun': 6,
        'Jul': 7,
        'Aug': 8,
        'Sep': 9,
        'Oct': 10,
        'Nov': 11,
        'Dec': 12
    }
    return months_dict[montinChar]
}
const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}
const validatePhoneNumber = (phoneNumber) => {
    const isValidNumber = /^\d{10}$/.test(phoneNumber);
    return isValidNumber
};
const SocialmediaValidation = (link) => {
    const facebookRegex = /^https?:\/\/(www\.)?facebook\.com\/.*/i;
    const twitterRegex = /^https?:\/\/(www\.)?twitter\.com\/.*/i;
    const instagramRegex = /^https?:\/\/(www\.)?instagram\.com\/.*/i;
    const linkedinRegex = /^https?:\/\/(www\.)?linkedin\.com\/.*/i;
    const youtubeRegex = /^https?:\/\/(www\.)?youtube\.com\/.*/i;
    const pinterestRegex = /^https?:\/\/(www\.)?pinterest\.com\/.*/i;
    // Check if the input matches any of the social media patterns
    const isFacebook = facebookRegex.test(link);
    const isTwitter = twitterRegex.test(link);
    const isInstagram = instagramRegex.test(link);
    const isLinkedin = linkedinRegex.test(link);
    const isYoutube = youtubeRegex.test(link);
    const isPinterest = pinterestRegex.test(link);
    // Set isValid to true if it matches any of the social media patterns
    if (isFacebook || isTwitter || isInstagram || isLinkedin || isYoutube || isPinterest) {
        return true
    }
    else {
        return false
    }
}
const langValidation = (language) => {
    const supportedLanguages = [
        'English',
        'Spanish',
        'French',
        'German',
        'Chinese',
        'Japanese',
        'Arabic',
        'Russian',
        'Portuguese',
        'Hindi',
        'Bengali',
        'Urdu',
        'Indonesian',
        'Turkish',
        'Vietnamese',
        'Thai',
        'Swedish',
        'Italian',
        'Dutch',
        'Polish',
        'Romanian',
        'Greek',
        'Hungarian',
        'Czech',
        'Finnish',
        'Danish',
        'Norwegian',
        'Korean',
        'Malay',
        'Filipino',
        'Hebrew',
        'Malayalam',
        'Tamil',
        'Telugu',
        'Kannada',
        'Marathi',
        'Gujarati',
        'Punjabi',
        'Sanskrit',
        'Nepali',
        'Sinhala',
        'Burmese',
        'Khmer',
        'Lao',
        'Mongolian',
        'Tibetan',
        'Georgian',
        'Armenian',
        'Azerbaijani',
        'Kazakh',
        'Uzbek',
        'Turkmen',
        'Kyrgyz',
        'Tajik',
        'Pashto',
        'Farsi (Persian)',
        'Dari',
        'Pashto',
        'Urdu',
        'Balochi',
        'Sindhi',
        'Punjabi',
        'Kashmiri',
        'Assamese',
        'Odia',
        'Maithili',
        'Bhojpuri',
        'Nepali',
        'Konkani',
        'Sindhi',
        'Dzongkha',
        // Add more languages as needed
    ];
    ;
    if (supportedLanguages.includes(language)) {
        return true
    }
    else {
        return false
    }
}
const submitpaymentStatus = async (datafromRes) => {
    let token = Cookies.get('userAuth')
    try {
        const response = await axios.post(apiUrl() + '/procced-payment-status',
            datafromRes,
            { headers: { 'Authorization': `Bearer ${token}` } })
        if (response.data.statusCode == "200") {

        }
        else {

        }

    } catch (error) {
        console.error(error);
    }
}
const recaptchaKey = ()=>{
    return '6LdFjlkpAAAAANzrXPmGesdjHd03fmHVuWm4dfqg'
}
export {
    apiUrl,
    getCookie,
    dataURLtoFile,
    aiAPIService,
    getCreditPoint,
    deductCreditPoints,
    getCreditPointDeducts,
    jwtVerify,
    monthswithNum,
    validateEmail,
    validatePhoneNumber,
    isDigitInRange,
    SocialmediaValidation,
    langValidation,
    submitpaymentStatus,
    recaptchaKey
}

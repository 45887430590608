import "../css/about.css";
export default function About() {
    return (
        <section id="features" className="features">

            <div className="container" data-aos="fade-up">

                <header className="section-header">

                    <p style={{ color: "#FF3052" }}>Discover about the TheJobSearch Story<br></br>  </p> <p>Revolutionizing Resumes, Transforming Careers, and Empowering Success.</p>
                </header>

                <div className="row">

                    <div className="col-lg-6">
                        <img src="assets/img/features.png" className="img-fluid" alt="" />
                    </div>

                    <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                        <div className="row align-self-center gy-4">

                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="200">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Unlock Your Potential: Your Perfect Resume Awaits.</h3>
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="300">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Career Boost: Where Resumes Lead to Opportunities.</h3>
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="400">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Confidence in Every Word: Crafted by Experts.</h3>
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="500">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Job Seeker's Partner: Navigating the Path to Success.</h3>
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="600">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Resume Reinvented: Your Gateway to a Brighter Future.</h3>
                                </div>
                            </div>

                            <div className="col-md-6" data-aos="zoom-out" data-aos-delay="700">
                                <div className="feature-box d-flex align-items-center">
                                    <i className="bi bi-check"></i>
                                    <h3>Experience the Magic: Elevating Your Career Trajectory.</h3>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {/* <div className="row feture-tabs" data-aos="fade-up">
            <div className="col-lg-6">
              <h3>Effortlessly make a job-worthy resume and cover letter that gets you hired faster</h3>

              <ul className="nav nav-pills mb-3">
                <li>
                  <a className="nav-link active" data-bs-toggle="pill" href="#tab1">Creative and Professional Resume Templates</a>
                </li>
                <li>
                  <a className="nav-link" data-bs-toggle="pill" href="#tab2">Edit Your Resume in Real Time</a>
                </li>
                <li>
                  <a className="nav-link" data-bs-toggle="pill" href="#tab3">Free AND Premium</a>
                </li>
              </ul>
              <div className="tab-content">

                <div className="tab-pane fade show active" id="tab1">
                  <p>Use our resume maker with its advanced creation tools to tell a professional story that engages recruiters, hiring managers and even CEOs.</p>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-check2"></i>
                    <h4>Make a resume that wins interviews</h4>
                  </div>
                  <p>Whatever resume template you're looking for, we've got it! Whether it's a classic black-and-white template, or something a bit more outside the box, we have what you need!</p>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-check2"></i>
                    <h4>Resume writing made easy</h4>
                  </div>
                  <p>Our resume templates are ATS-friendly. It means your resume won’t automatically be rejected because an ATS can’t read it..</p>
                </div>

                <div className="tab-pane fade show" id="tab2">
                  <p>You won’t spend hours working on your resume, just to be hit with a hidden paywall. Our resume builder will notify you if you’re using any of our premium features in advance.</p>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-check2"></i>
                    <h4>NO Hidden Fees</h4>
                  </div>
                  <p>With Novorésumé, you won’t spend hours working on your resume, just to be hit with a hidden paywall. Our resume builder will notify you if you’re using any of our premium features in advance..</p>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-check2"></i>
                    <h4>Free AND Premium</h4>
                  </div>
                  <p>We offer both free and premium features. Want your resume to have that extra punch? Upgrade to Premium. On a budget? That's OK too - you can use our resume buider completely free of charge.</p>
                </div>

                <div className="tab-pane fade show" id="tab3">
                  <p>Consequuntur inventore voluptates consequatur aut vel et. Eos doloribus expedita. Sapiente atque consequatur minima nihil quae aspernatur quo suscipit voluptatem.</p>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-check2"></i>
                    <h4>Repudiandae rerum velit modi et officia quasi facilis</h4>
                  </div>
                  <p>Laborum omnis voluptates voluptas qui sit aliquam blanditiis. Sapiente minima commodi dolorum non eveniet magni quaerat nemo et.</p>
                  <div className="d-flex align-items-center mb-2">
                    <i className="bi bi-check2"></i>
                    <h4>Incidunt non veritatis illum ea ut nisi</h4>
                  </div>
                  <p>Non quod totam minus repellendus autem sint velit. Rerum debitis facere soluta tenetur. Iure molestiae assumenda sunt qui inventore eligendi voluptates nisi at. Dolorem quo tempora. Quia et perferendis.</p>
                </div>

              </div>

            </div>

            <div className="col-lg-6">
              <img src="assets/img/features-2.png" className="img-fluid" alt=""/>
            </div>

          </div> */}

                <div className="row feature-icons" data-aos="fade-up">
                    <h3>Empowering Dreams, One Resume at a Time.</h3>

                    <div className="row">

                        <div className="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
                            <img src="assets/img/features-3.png" className="img-fluid p-4" alt="" />
                        </div>

                        <div className="col-xl-8 d-flex content">
                            <div className="row align-self-center gy-4">

                                <div className="col-md-6 icon-box" data-aos="fade-up">
                                    <i className="ri-line-chart-line"></i>
                                    <div>
                                        <h4>At Magicpoint</h4>
                                        <p>We're on a mission to revolutionize the way you approach your career journey. Our team of experts has crafted a cutting-edge platform that combines intelligent AI technology with human-centered design, resulting in a seamless resume-building experience like no other.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
                                    <i className="ri-stack-line"></i>
                                    <div>
                                        <h4>With Magicpoint</h4>
                                        <p>You're not just creating a resume; you're shaping your professional destiny. Our user-friendly interface makes it effortless to choose from a stunning array of templates and start customizing your resume. But we don't stop there.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                    <i className="ri-brush-4-line"></i>
                                    <div>
                                        <h4>AI-powered</h4>
                                        <p>Our AI-powered content suggestions ensure that your resume is not just visually appealing but also packed with the right keywords and professionally curated content. We're your personal career companion, helping you stand out in a competitive job market.</p>
                                    </div>
                                </div>

                                <div className="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
                                    <i className="ri-brush-4-line"></i>
                                    <div>
                                        <h4>Join Us</h4>
                                        <p>Join us on a journey where innovation meets career success. From students and early job seekers to seasoned professionals, Magicpoint is here to empower every step of your career path, because we believe that everyone deserves a shot at their dream job. Experience the magic today and unlock a world of opportunities. Your future begins here.</p>
                                    </div>
                                </div>



                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </section>
    )
}
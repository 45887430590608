export default function TAC(){
    return(
        <section style={{fontFamily:"Verdana"}} id="features" className="features">

        <div  className="container" data-aos="fade-up">
  
          

    <div className="row feature-icons" data-aos="fade-up">
            <h3 style={{fontFamily:"sans-serif"}}>Magicpoint Terms and Conditions</h3>
            
  
              <div className="col-xl-12 d-flex content">
                
                <div className="row align-self-center gy-4">
                <header className="section-header">
                  <h2 style={{color:"black"}}>Effective Date: 1st October 2023</h2> 
                </header>
  
                  <div className="col-md-12 icon-box" data-aos="fade-up">
                    <div>
                      <h4>1. Acceptance of Terms and Conditions</h4>
                      <p style={{color:"black"}}>Welcome to Magicpoint ("Portal"). By accessing or using this Portal, you agree to comply with and be bound by these Terms and Conditions ("Terms"). If you do not agree with these Terms, please do not use the Portal.
</p>
                    </div>
                  </div>
  
                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                    
                    <div>
                      <h4>2. Definitions</h4>
                      <p style={{color:"black"}}>"Portal" refers to the website located at [magicpoint.in], its subdomains, and associated services.</p>
                      <p style={{color:"black"}}>"Company" refers to Magic Point, a registered entity in India.</p>
                      <p style={{color:"black"}}>"User" refers to any individual or entity accessing or using the Portal.</p>
                      <p style={{color:"black"}}>"Content" refers to text, graphics, images, videos, and any other materials available on the Portal.</p>
                    </div>
                  </div>
  
                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>3. Use of the Portal</h4>
                      <p style={{color:"black"}}>- Users must be at least 18 years old to use the Portal.</p>
                      <p style={{color:"black"}}>- Users agree to provide accurate, current, and complete information during the registration process.</p>
                      <p style={{color:"black"}}>- Users are responsible for maintaining the confidentiality of their account credentials.</p>
                    </div>
                  </div>
  
                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>4. Intellectual Property</h4>
                      <p style={{color:"black"}}>- The Portal and its content are protected by copyright and other intellectual property laws.</p>
                      <p style={{color:"black"}}>- Users may not reproduce, modify, distribute, or publicly display any content from the Portal without prior written consent from the Company.</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>5. User-Generated Content</h4>
                      <p style={{color:"black"}}>- Users may submit content to the Portal. By doing so, users grant the Company a worldwide, non-exclusive, royalty-free license to use, reproduce, and distribute the content.</p>
                      <p style={{color:"black"}}>- Users are responsible for the legality and accuracy of the content they submit.</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>6. Privacy Policy</h4>
                      <p style={{color:"black"}}>- Our Privacy Policy, available on the Portal, explains how user data is collected, used, and protected</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>7. Disclaimer of Warranties</h4>
                      <p style={{color:"black"}}>- The Portal is provided "as is" and "as available" without warranties of any kind.</p>
                      <p style={{color:"black"}}>- The Company makes no guarantees regarding the accuracy, completeness, or reliability of the content.</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>8. Limitation of Liability</h4>
                      <p style={{color:"black"}}>- The Company shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising from the use of the Portal.</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>9. Governing Law and Jurisdiction</h4>
                      <p style={{color:"black"}}>- These Terms are governed by the laws of India, and any disputes shall be subject to the exclusive jurisdiction of the courts in  India.</p>
                    </div>
                  </div>
  
                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>10. Modifications to Terms</h4>
                      <p style={{color:"black"}}>- The Company reserves the right to modify or update these Terms at any time. Users will be notified of such changes, and continued use of the Portal constitutes acceptance of the revised Terms.
</p>
                    </div>
                  </div>
  
                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>11. Termination</h4>
                      <p style={{color:"black"}}>- The Company may terminate or suspend a user's account and access to the Portal at its discretion, without notice.</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>12. Contact Information</h4>
                      <p style={{color:"black"}}>- For questions or concerns regarding these Terms and Conditions, please contact us at magicpointhr@gmail.com</p>
                    </div>
                  </div>


                  <header className="section-header">
                  <h2 style={{color:"black"}}>By using the Magicpoint Portal, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.</h2> 
                </header>
                </div>
              </div>
  
            
  
          </div>

          </div>
          </section>
  )   
}
export default function PP(){
    return(
        <section style={{fontFamily:"Verdana"}} id="features" className="features">

        <div className="container" data-aos="fade-up">
  
          

    <div className="row feature-icons" data-aos="fade-up">
            <h3 style={{fontFamily:"sans-serif"}}>Magicpoint Privacy Policy </h3>
            
  
              <div className="col-xl-12 d-flex content">
                
                <div className="row align-self-center gy-4">
                <header className="section-header">
                  <h2 style={{color:"black"}}>Effective Date: 1st October 2023</h2> 
                </header>
  
                  <div className="col-md-12 icon-box" data-aos="fade-up">
                    <div>
                      <h4>1. Introduction</h4>
                      <p style={{color:"black"}}>Welcome to Magicpoint ("Portal"). This Privacy Policy outlines how Magic Point, a registered entity in India, ("Company," "we," "us," or "our") collects, uses, discloses, and protects your personal information when you access and use the Portal. We are committed to safeguarding your privacy and ensuring the security of your personal information.</p>
                    </div>
                  </div>
  
                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="100">
                    
                    <div>
                      <h4>2. Information We Collect</h4>
                      <p style={{color:"black"}}>Personal Information: When you use our Portal, we may collect personal information such as your name, email address, contact information, and any other information you voluntarily provide.</p>
                      <p style={{color:"black"}}>Usage Information: We may collect information about how you access and use the Portal, including your IP address, browser type, operating system, and device information.</p>
                    </div>
                  </div>
  
                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>3. How We Use Your Information</h4>
                      <p style={{color:"black"}}>We may use your personal information for the following purposes:</p>
                      <p style={{color:"black"}}>- To provide and improve the Portal's services.</p>
                      <p style={{color:"black"}}>- To communicate with you, respond to your inquiries, and provide customer support.</p>
                      <p style={{color:"black"}}>- To send you updates, promotional materials, and other information related to the Portal.</p>
                      <p style={{color:"black"}}>- To monitor and analyze usage patterns and trends to enhance the user experience.</p>
                      <p style={{color:"black"}}>- To comply with legal obligations and enforce our Terms and Conditions.</p>
                    </div>
                  </div>
  
                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>4. Information Sharing</h4>
                      <p style={{color:"black"}}>We may share your personal information in the following circumstances:</p>
                      <p style={{color:"black"}}>- With third-party service providers who assist us in operating and maintaining the Portal.</p>
                      <p style={{color:"black"}}>- When required by law or legal process.</p>
                      <p style={{color:"black"}}>- In connection with the sale, merger, or acquisition of all or part of our company.</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>5. Data Security</h4>
                      <p style={{color:"black"}}>We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, and destruction. However, please be aware that no method of data transmission over the internet is entirely secure.</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>6. Your Choices</h4>
                      <p style={{color:"black"}}>You have the right to:</p>
                      <p style={{color:"black"}}>- Access, update, or correct your personal information.</p>
                      <p style={{color:"black"}}>- Withdraw consent for the processing of your personal information.</p>
                      <p style={{color:"black"}}>- Delete your account and personal data.</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>7. Cookies and Tracking Technologies</h4>
                      <p style={{color:"black"}}>We may use cookies and similar tracking technologies to collect information about your interactions with the Portal. You can manage your cookie preferences through your browser settings.</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>8. Changes to this Privacy Policy</h4>
                      <p style={{color:"black"}}>We reserve the right to modify or update this Privacy Policy. Any changes will be posted on this page with an updated "Effective Date." Your continued use of the Portal following such changes constitutes your acceptance of the revised Privacy Policy</p>
                    </div>
                  </div>

                  <div className="col-md-12 icon-box" data-aos="fade-up" data-aos-delay="200">
                    
                    <div>
                      <h4>9. Contact Us</h4>
                      <p style={{color:"black"}}>For questions or concerns regarding this Privacy Policy or our data practices, please contact us at [Contact Information].</p>
                    </div>
                  </div>
  


                  <header className="section-header">
                  <h2 style={{color:"black"}}>By accessing and using the Magicpoint Portal, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</h2>
                </header>
                <h4 style={{color:"black"}}>Remember to adapt this template to your specific website and business practices. Consulting with a legal professional is essential to ensure compliance with Indian privacy laws and regulations.</h4>
                </div>
                
              </div>
  
            
  
          </div>

          </div>
          </section>
  )   
}
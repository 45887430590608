import "../css/checkout.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { apiUrl, jwtVerify, submitpaymentStatus,recaptchaKey } from "../function";
import Cookies from "js-cookie";
import ReCAPTCHA from 'react-google-recaptcha';
import { useRef } from 'react';
import useRazorpay from "react-razorpay";
export default function Checkout() {
    const [Razorpay] = useRazorpay();
    var urlParams = new URLSearchParams(window.location.search);
    var paramValue = urlParams.get('packageId');
    const [title, setTitle] = useState('')
    const [price, setPrice] = useState('')
    const [planImg, setplanImg] = useState('')
    const [successMsg, setSuccessmsg] = useState('')
    const [errorMsg, setErrormsg] = useState('')
    const [formData, setFormData] = useState({});
    const recaptchaRef = useRef(null);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const getPackageMasterData = async () => {
        try {
            const response = await axios.get(apiUrl() + '/get-package?packageId=' + paramValue);
            if (response.data.statusCode == "200") {

                setTitle(response.data.packageData[0].package_name)
                setPrice(response.data.packageData[0].price)
                setplanImg(response.data.packageData[0].image)
                setFormData({
                    'pay_ammount': response.data.packageData[0].price,
                    name: jwtVerify("userAuth").name,
                    email: jwtVerify("userAuth").email,
                    phone: '',
                    country: '',
                    city: '',
                    address: '',
                    pincode: '',
                    packageId: paramValue
                })
            }
        } catch (error) {
            console.error(error);
        }
    }
    let handleSubmit = async (event) => {
        event.preventDefault();
        //Verify reCAPTCHA
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            setErrormsg('Please complete the reCAPTCHA.');
            return;
        }
        let token = Cookies.get('userAuth')

        for (let key in formData) {
            if (formData[key] == '') {
                setSuccessmsg('')
                setErrormsg('All field are required')
                return;
            }
        }
        try {
            const response = await axios.post(apiUrl() + '/procced-payment',
                formData,
                { headers: { 'Authorization': `Bearer ${token}` } })
            if (response.data.statusCode == "200") {
                setErrormsg('')
                setSuccessmsg('Please wait we redairect you payment gatway')
                handlePayment(response.data.order.id)
            }
            else {
                setSuccessmsg('')
                setErrormsg('Somthing is problem')
            }

        } catch (error) {
            console.error(error);
        }
    }
    const handlePayment = async (params) => {
        setSuccessmsg(' ')
        const order = params //await createOrder(params); //  Create order on your backend

        const options = {
            key: "rzp_test_RxuG7ipnPTh0QN", // Enter the Key ID generated from the Dashboard
            amount: "50", // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            currency: "INR",
            name: "Magic Point",
            description: "Test Transaction",
            image: "https://magicpoint.in/favicon.png",
            order_id: params,
            handler: function (response) {
                submitpaymentStatus(response)
            },
            prefill: {
                name: formData.name,
                email: formData.email,
                contact: formData.phone,
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#3399cc",
            },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
            submitpaymentStatus(response)
        });

        rzp1.open();
    };
    useEffect(() => {
        getPackageMasterData()

    }, [])

    return (
        <div className="container" style={{ marginTop: '100px' }}>
            <div className="row">
                <div className="col-xl-7">
                    <div className="card">
                        <div className="card-body">
                            <ol className="activity-checkout mb-0 px-4 mt-3">
                                <li className="checkout-item">
                                    <div className="feed-item-list">
                                        <div>
                                            <h5 className="font-size-16 mb-1">Billing Info</h5>
                                            <p className="text-muted text-truncate mb-4">
                                                Please enter few details
                                            </p>
                                            <div className="mb-3">
                                                <form>
                                                    <div>
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="mb-3">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="billing-name"
                                                                    >
                                                                        Name*
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="billing-name"
                                                                        placeholder="Enter name"
                                                                        name="name"
                                                                        onChange={handleInputChange}
                                                                        value={formData.name}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="mb-3">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="billing-email-address"
                                                                    >
                                                                        Email Address*
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="billing-email-address"
                                                                        placeholder="Enter email"
                                                                        name="email"
                                                                        onChange={handleInputChange}
                                                                        value={formData.email}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="mb-3">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="billing-phone"
                                                                    >
                                                                        Phone*
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="billing-phone"
                                                                        placeholder="Enter Phone no."
                                                                        name="phone"
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label
                                                                className="form-label"
                                                                htmlFor="billing-address"
                                                            >
                                                                Address*
                                                            </label>
                                                            <textarea
                                                                className="form-control"
                                                                id="billing-address"
                                                                rows={3}
                                                                placeholder="Enter full address"
                                                                defaultValue={""}
                                                                name="address"
                                                                onChange={handleInputChange}
                                                            />
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-lg-4">
                                                                <div className="mb-4 mb-lg-0">
                                                                    <label className="form-label">Country*</label>
                                                                    <select
                                                                        className="form-control form-select"
                                                                        title="Country"
                                                                        name="country"
                                                                        onChange={handleInputChange}
                                                                    >
                                                                        <option value={0}>Select Country</option>
                                                                        <option value="AF">Afghanistan</option>
                                                                        <option value="AL">Albania</option>
                                                                        <option value="DZ">Algeria</option>
                                                                        <option value="AS">American Samoa</option>
                                                                        <option value="AD">Andorra</option>
                                                                        <option value="AO">Angola</option>
                                                                        <option value="AI">Anguilla</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="mb-4 mb-lg-0">
                                                                    <label
                                                                        className="form-label"
                                                                        htmlFor="billing-city"
                                                                    >
                                                                        City*
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="billing-city"
                                                                        placeholder="Enter City"
                                                                        name="city"
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4">
                                                                <div className="mb-0">
                                                                    <label className="form-label" htmlFor="zip-code">
                                                                        Zip / Postal code*
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="zip-code"
                                                                        placeholder="Enter Postal code"
                                                                        name="pincode"
                                                                        onChange={handleInputChange}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>

                                                </form>
                                            </div>

                                        </div>
                                        <div className="col">
                                            <div className="text-end mt-2 mt-sm-0">
                                                <span className="text-danger">{errorMsg}</span>
                                                <span className="text-success">{successMsg}</span>
                                            </div>
                                        </div>
                                    </div>
                                </li>

                            </ol>

                        </div>

                    </div>
                    <div className="row my-4">
                        <div className="col">

                            <Link to="/pricing" className="btn btn-link text-muted">
                                <i className="mdi mdi-arrow-left me-1" /> Change Plan{" "}
                            </Link>
                        </div>
                        <div className="col">
                            <div className="text-end mt-2 mt-sm-0">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    sitekey={recaptchaKey()}
                                    onChange={() => { }}
                                />
                            </div>
                        </div>
                        <div className="col">
                            <div className="text-end mt-2 mt-sm-0">
                                <button className="btn btn-success" onClick={handleSubmit}>Procced Payment</button>
                            </div>
                        </div>{" "}
                        {/* end col */}
                    </div>{" "}
                    {/* end row*/}
                </div>
                <div className="col-xl-5">
                    <div className="card checkout-order-summary">
                        <div className="card-body">
                            <div className="p-3 bg-light mb-3">
                                <h5 className="font-size-16 mb-0">
                                    Order Summary <span className="float-end ms-2">#MN0124</span>
                                </h5>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-centered mb-0 table-nowrap">
                                    <thead>
                                        <tr>
                                            <th
                                                className="border-top-0"
                                                style={{ width: 110 }}
                                                scope="col"
                                            >
                                                Plan
                                            </th>
                                            <th className="border-top-0" scope="col">
                                                Title
                                            </th>
                                            <th className="border-top-0" scope="col">
                                                Price
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">
                                                <img
                                                    src={apiUrl() + "/assest/pricing/" + planImg}
                                                    alt="product-img"
                                                    title="product-img"
                                                    className=""
                                                />
                                            </th>
                                            <td>
                                                <h5 className="font-size-16 text-truncate">
                                                    <a href="#" className="text-dark">
                                                        {title}
                                                    </a>
                                                </h5>
                                                <p className="text-muted mb-0">
                                                    <i className="bx bxs-star text-warning" />
                                                    <i className="bx bxs-star text-warning" />
                                                    <i className="bx bxs-star text-warning" />
                                                    <i className="bx bxs-star text-warning" />
                                                </p>
                                                {/* <p className="text-muted mb-0 mt-1">$ 260 x 1</p> */}
                                            </td>
                                            <td>₹ {price}</td>
                                        </tr>
                                        <tr className="bg-light">
                                            <td colSpan={2}>
                                                <h5 className="font-size-14 m-0">Total:</h5>
                                            </td>
                                            <td>₹ {price}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* end row */}
        </div>

    )
}
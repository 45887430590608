import Sidebarmenue from "../components/sidebar"
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { apiUrl } from "../function";
import Cookies from "js-cookie";
import { Link } from "react-router-dom";

import "../css/myDetails.css";


var base64 = require('base-64');
export default function MyDetails() {
  ;
  const [profilePic, setprofilePic] = useState('https://img.icons8.com/color/120/user.png')
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [encodeValue, setEncodevalue] = useState(false);
  const getuserDetails = async () => {
    try {
      let authToken = Cookies.get('userAuth');
      let headers = {
        Authorization: `Bearer ${authToken}`,
      };
      let response = await axios.get(apiUrl() + '/mydetails', { headers });
      if (response.data.statusCode == 200) {
        if (response.data.userData[0].profile_pic != null) {
          setprofilePic(response.data.userData[0].profile_pic)
        }

        setName(response.data.userData[0].user_name)
        setEmail(response.data.userData[0].email)
        var encoded = base64.encode(response.data.userData[0].user_name, response.data.userData[0].email);
        setEncodevalue(encoded)
      }

    } catch (error) {
      console.error(error);
    }

  };

  useEffect(() => {
    getuserDetails()
  }, [])
  return (
    <>
      <div style={{ display: 'flex', height: '100%', minHeight: '400px', marginTop: "100px" }}>
        <Sidebarmenue />

        <div style={{ width: "80%", height: "100vh", backgroundColor: "white", boxShadow: "0 3px 7px rgba(0, 0, 0, 0.2)", display: "flex", flexDirection: "column", alignItems: "center" }}>
          <h2 style={{ fontSize: "25px", fontFamily: "Verdana", margin: "20px", fontWeight: "bolder", color: "#0D3779" }}>My Details</h2>
          <div className="container table-responsive">
            <table className="table-responsive-sm table  table-bordered text-center ">
              <thead>
                <tr >
                  <th className="table-header" scope="col">Profile</th>
                  <th className="table-header" scope="col">Name</th>
                  <th className="table-header" scope="col">Email</th>
                  <th className="table-header" scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr style={{ margin: 'auto' }}>
                  <th scope="row"><img style={{ height: '150px' }} src={profilePic} /></th>
                  <td className="text-center align-middle">{name}</td>
                  <td className="text-center align-middle">{email}</td>
                  <td className="text-center align-middle"><Link to={"/profileupdate"} className="edtr_btn ">Edit</Link></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </>
  )
}
import React, { useEffect, useState } from 'react';
import "../css/workExpe.css";
import Validate from "../../validation";
import axios from "axios";
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { Aisuggesstionmodal } from '../modal/Aimodal';
import { apiUrl, jwtVerify, monthswithNum, aiAPIService, getCreditPoint, deductCreditPoints, getCreditPointDeducts } from '../../function';
const vld = new Validate
function Workexpe({ onWorkExpeChange }) {
    const [col, setCol] = useState('4')
    const [formValues, setFormValues] = useState([{ heading: 'WORK EXPERIENCE', company: '', jobTitle: '', currentCompany: ' ', workSinceYear: '', worksincemonth: '', workSincetoYear: '', worksincetomonth: '', descp: '', workedTill: 'Present', errorMsg: '' }]);
    const [year, setYear] = useState('')
    const [month, setMonth] = useState('')
    const [addjobBtn, setaddjobBtn] = useState(true)
    const [validate, setValidate] = useState(false)
    const [isModelButtonVisible, setIsModelButtonVisible] = useState(false);
    const [creditPoints, setCreditPoints] = useState(null);
    const [creditPointsDeduct, setCreditPointsDeduct] = useState(null);
    const [aiSuggesstion, setAiSuggesstion] = useState();
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    let handleChange = (e, i) => {
        let newFormValues = [...formValues];
        if (e.target.name == "workSincetoYear" || e.target.name == "workSinceYear" || e.target.name == "worksincemonth" || e.target.name == "worksincetomonth") {
            if (e.target.name == "workSinceYear" && e.target.value > formValues[i].workSincetoYear) {
                formValues[i].errorMsg = "Work since Year must be less then  Work Till year!"
                setValidate(false)
            }
            else if (e.target.name == "workSincetoYear" && formValues[i].workSinceYear > e.target.value) {
                formValues[i].errorMsg = "Work Till year must be greater then work since year!"
                setValidate(false)
            }
            else if (e.target.name == "worksincetomonth" && formValues[i].workSinceYear == formValues[i].workSincetoYear && monthswithNum(formValues[i].worksincemonth) >= monthswithNum(e.target.value)) {
                formValues[i].errorMsg = "Work Till month must be greater then work since month!"
                setValidate(false)
            }
            else if (e.target.name == "worksincemonth" && formValues[i].workSinceYear == formValues[i].workSincetoYear && monthswithNum(formValues[i].worksincetomonth) <= monthswithNum(e.target.value)) {
                formValues[i].errorMsg = "Work Since month must be less then work Till month!"
                setValidate(false)
            }
            else {
                formValues[i].errorMsg = " "
                setValidate(true)
            }
        }

        if (e.target.name == "currentCompany" && e.target.value == "Yes") {
            setaddjobBtn(false)
        }
        else {
            setaddjobBtn(true)
        }
        if ((e.target.name == "descp") && (e.target.value.length >= 20)) {
            setIsModelButtonVisible(true);
        }
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    let addFormFields = () => {
        setIsModelButtonVisible(false);
        setFormValues([...formValues, { heading: '', company: '', jobTitle: '', currentCompany: '', workSinceYear: '', worksincemonth: "", workSincetoYear: '', worksincetomonth: '', descp: '', workedTill: 'Present', errorMsg: '' }])
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
        onWorkExpeChange(newFormValues); // Notify parent about the change
    }

    let handleSubmit = async (event) => {
        console.log('---formValues---', formValues);
        let token = Cookies.get('userAuth')
        event.preventDefault();
        if (!validate) {
            return
        }
        onWorkExpeChange(formValues); // Notify parent about the change

        try {
            console.log('--tryyy-formValues---', formValues);
            const response = await axios.post(apiUrl() + '/submit-resume',
                { Workexpe: formValues },
                { headers: { 'Authorization': `Bearer ${token}` } })
            if (response.data.statusCode == "200") {

            }
            else {

            }

        } catch (error) {

        }

    }

    async function getYM() {
        try {
            let response = await axios.get(apiUrl() + '/get-YM');
            let yearOption = response.data.Iyear.map((item, index) => {
                return <option key={index}>{item.year}</option>
            })
            setYear(yearOption)
            let monthOption = response.data.Smonth.map((item, index) => {
                return <option key={index}>{item.month}</option>
            })
            setMonth(monthOption)
        } catch (error) {

        }
    }
    const onOpenModal = (content, content_type) => {
        callAIApi(content, content_type);
        setOpen(true);
    }
    const onCloseModal = () => setOpen(false);
    const callAIApi = async (content, content_type) => {
        setIsLoading(true);
        let aiAPIRes = await aiAPIService(content, content_type);
        if (aiAPIRes) {
            setIsLoading(false)

            setAiSuggesstion(aiAPIRes);
        }
        else {
            setIsLoading(true);
        }
    }
    const getCreditP = async () => {
        let token = Cookies.get('userAuth')
        let creditPT = await getCreditPoint(token);
        if (creditPT) {
            setCreditPoints(creditPT);
        }
    }
    const getCreditPointDeduct = async () => {
        let creditPointDeduct = await getCreditPointDeducts();
        if (creditPointDeduct) {
            setCreditPointsDeduct(creditPointDeduct);
        }
    }
    const deductCreditPts = async (value) => {
        let token = Cookies.get('userAuth')
        if (creditPoints >= value) {
            let availCreditPoints = creditPoints - value;
            let updateCP = await deductCreditPoints(availCreditPoints, token);
            setCreditPoints(availCreditPoints);
            getCreditP();
        }
        else if (creditPoints == 0) {
            setCreditPoints(0);
        }
    };
    useEffect(() => {
        getYM()
        let x = localStorage.getItem("WXP" + jwtVerify("userAuth").userId);
        if (x != null) {
            x = JSON.parse(x);
            setFormValues(x)
            onWorkExpeChange(x);
        }
        else {
            let usertoken = Cookies.get('userAuth')
            getUserWorkExp(usertoken);
        }
        // API call for getCreditPoint ----------------------
        getCreditP();
        getCreditPointDeduct();
    }, [])



    const getUserWorkExp = (usertoken) => {

        return axios.get(apiUrl() + `/get-users-work-exp-detail`,
            { headers: { 'Authorization': `Bearer ${usertoken}` } })
            .then(response => {
                if (response.data.statusCode == "200") {
                    setFormValues(response.data.userWorkExpData);
                } else {

                }
            })
            .catch(error => {

            });
    };

    return (
        <>
            <form onSubmit={handleSubmit} className="">
                {formValues.map((element, index) => (

                    <div className="form-inline" key={index}>
                        <section style={{ marginBottom: '10px' }} className="flex flex-col gap-3 rounded-md bg-white p-6 pt-4 shadow transition-opacity duration-200 transition-opacity duration-200 pb-6">
                            <div className="row">
                                <div className="flex grow items-center gap-2"><svg xmlns="http://www.w3.org/2000/svg"
                                    fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                    aria-hidden="true" className="h-6 w-6 text-gray-600">
                                    <path strokeWidth="round" strokeLinejoin="round"
                                        d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21">
                                    </path>
                                </svg>
                                    <input
                                        className="block w-full border-b border-transparent text-lg font-semibold tracking-wide text-gray-900 outline-none hover:border-gray-300 hover:shadow-sm focus:border-gray-300 focus:shadow-sm"
                                        type="text"
                                        name="heading" value={element.heading} onChange={e => handleChange(e, index)}

                                    />


                                </div>


                            </div>
                            <div className="grid overflow-hidden transition-all duration-300 visible"
                                style={{ gridTemplateRows: "1fr" }}>
                                <div className="min-h-0">
                                    <div className="row">
                                        <label
                                            className="text-base font-medium text-gray-700 col-span-full mt-3">Company


                                            <input
                                                className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                type="text"
                                                placeholder="Name"
                                                name="company" value={element.company || ""} onChange={e => handleChange(e, index)}
                                            />

                                        </label>
                                        <label
                                            className="text-base font-medium text-gray-700 col-span-full mt-3">Designation

                                            <input
                                                placeholder="Software Engineer"
                                                className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                type="text"
                                                name="jobTitle" value={element.jobTitle || ""} onChange={e => handleChange(e, index)}

                                            />

                                        </label>
                                        <label
                                            className="text-base font-medium text-gray-700 col-span-full mt-3">Is this your current company

                                            <select
                                                placeholder="Software Engineer"
                                                className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                type="text"
                                                name="currentCompany" value={element.currentCompany || ""}
                                                onChange={e => handleChange(e, index)}

                                            >
                                                <option>Select</option>
                                                <option>Yes</option>
                                                <option>No</option>
                                            </select>

                                        </label>

                                        <label className="col-sm-3 mt-3">Working Since
                                            <select
                                                className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                value={element.workSinceYear || ""} name="workSinceYear"
                                                onChange={e => handleChange(e, index)}

                                            >
                                                <option>Year</option>
                                                {year}
                                            </select>

                                        </label>
                                        <label
                                            className="col-sm-3  mt-3">&nbsp;
                                            <select
                                                className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                value={element.worksincemonth || ""} name="worksincemonth"
                                                onChange={e => handleChange(e, index)}
                                            >
                                                <option>Month</option>
                                                {month}
                                            </select>

                                        </label>


                                        {element.currentCompany == "Yes" ? (
                                            <label className="col-sm-6  mt-3">Worked Till
                                                <input className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                    type="text" name="workedTill" value={element.workedTill || ""}
                                                    onChange={e => handleChange(e, index)}
                                                />
                                            </label>
                                        ) :
                                            <>

                                                <label className="col-sm-3 mt-3">Worked Till
                                                    <select
                                                        className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                        value={element.workSincetoYear || ""} name="workSincetoYear"
                                                        onChange={e => handleChange(e, index)}
                                                    >
                                                        <option>Year</option>
                                                        {year}
                                                    </select>

                                                </label>

                                                <label
                                                    className="col-sm-3  mt-3">&nbsp;
                                                    <select
                                                        className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                        value={element.worksincetomonth || ""} name="worksincetomonth"
                                                        onChange={e => handleChange(e, index)}
                                                    >
                                                        {month}
                                                    </select>

                                                </label>
                                                <span className='text-danger text-center mt-2'>{element.errorMsg}</span>
                                            </>
                                        }








                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label className="text-base font-medium text-gray-700 col-span-full mt-3">Description</label>
                                            </div>
                                            <div className="col-sm-6">
                                                {isModelButtonVisible ? (
                                                    creditPoints > 0 ? (
                                                        <a>
                                                            <button
                                                                type="button"
                                                                className="btn btn-link creds"
                                                                onClick={() => {
                                                                    onOpenModal(element.descp, 'education');
                                                                }}
                                                            >
                                                                View Suggestion
                                                            </button>
                                                        </a>
                                                    ) : (
                                                        // <label className="text-base font-medium text-gray-700 col-span-full mt-3">Get More Credit Point</label>
                                                        <Link to='/pricing' className="btn btn-link nocreds">
                                                            Get More Credit Point</Link>
                                                    )
                                                ) : (
                                                    <p></p>
                                                )}
                                                {isModelButtonVisible ?
                                                    <Aisuggesstionmodal
                                                        isLoading={isLoading}
                                                        open={open}
                                                        aiSuggesstion={aiSuggesstion}
                                                        handlechangeAitext={handleChange}
                                                        closeModal={onCloseModal}
                                                        ondeductCreditPts={deductCreditPts}
                                                        for={"descp"}
                                                        indexNum={index}
                                                    />
                                                    : ''}
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-sm-12">
                                                <textarea
                                                    placeholder="Description"
                                                    className="mt-1 px-3 py-2 block w-full rounded-md border border-gray-300 text-gray-900 shadow-sm outline-none font-normal text-base"
                                                    type="text" style={{ height: "88px" }}
                                                    name="descp" value={element.descp || ""}
                                                    onChange={e => handleChange(e, index)}

                                                ></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-2 flex justify-end">

                            </div>

                        </section>
                        <div className="mt-2 flex justify-end">
                            {
                                index ?
                                    <button type="button" onClick={() => removeFormFields(index)}><i className="bi bi-trash3-fill"></i></button>
                                    : null
                            }

                        </div>


                    </div>
                ))}

                <div className="mt-2 flex justify-end">


                    <button
                        type="submit"
                        // onClick={handleSave}
                        className="btn-space flex items-center rounded-md bg-white py-2 pl-3 pr-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"><svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                            className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"  >
                            <path strokeWidth="round" strokeLinejoin="round" d="M12 6v12m6-6H6"></path>
                        </svg>Save</button>


                    <button type="button" style={{ display: addjobBtn ? '' : 'none' }}
                        onClick={() => addFormFields()}
                        className="flex items-center rounded-md bg-white py-2 pl-3 pr-4 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"><svg
                            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            strokeWidth="1.5" stroke="currentColor" aria-hidden="true"
                            className="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400 ">
                            <path strokeWidth="round" strokeLinejoin="round" d="M12 6v12m6-6H6"></path>
                        </svg>Add Job</button>
                </div>
            </form>


        </>
    )
}

export default Workexpe;

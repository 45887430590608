import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, ProSidebarResult } from "react-pro-sidebar";
import DashboardIcon from '@mui/icons-material/Dashboard';
import WestIcon from '@mui/icons-material/West';
import DetailsOutlinedIcon from '@mui/icons-material/DetailsOutlined';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import LogoutIcon from '@mui/icons-material/Logout';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import NoteIcon from '@mui/icons-material/Note';
import DraftsIcon from '@mui/icons-material/Drafts';
import Cookies from 'js-cookie';
export default function Sidebarmenue() {
  const [collapsed, setCollapsed] = React.useState(false);
  const logout = () => {
    Cookies.remove('userAuth')
    window.location.reload()
  }
  return (
    <Sidebar style={{ margin: '0px', boxShadow: '0 0 27px 0 rgba(214, 231, 233, 0.52)', border: '1px solid rgba(0, 0, 0, 0.05)' }} collapsed={collapsed}>
      <Menu style={{ color: "#134191", fontWeight: "700", fontFamily: "Nunito" }}>
        <MenuItem><button className="sb-button" onClick={() => setCollapsed(!collapsed)}>
          <WestIcon />
        </button></MenuItem>
        <MenuItem ><DashboardIcon /> <Link to="/DashBoard">Dashboard</Link> </MenuItem>
        <MenuItem active><ManageAccountsIcon /><Link to="/MyDetails"> My Details</Link>  </MenuItem>
        <MenuItem active><NoteIcon /><Link to="/userCoverLetter"> Cover Letter</Link>  </MenuItem>
        {/* <SubMenu label="Cover Letters" icon={<DraftsIcon />} >
          <MenuItem > List Of Cover Letters</MenuItem>
          <MenuItem > Create a Cover Letter</MenuItem>
        </SubMenu> */}
        <MenuItem ><CreditScoreIcon /><Link to="/credit-points"> Credits Available</Link>  </MenuItem>
        <MenuItem><CardMembershipIcon /><Link to="/current-plan"> Current Plan</Link></MenuItem>
        <MenuItem><LogoutIcon /><Link onClick={() => { logout() }} to="#" className=" "> Logout</Link></MenuItem>
      </Menu>
    </Sidebar>
  )
}